import React, {useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Textarea,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/core';
import {Formik} from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/app';
import {getRandomCode} from '../../Utils/utils';

export default function AddDiscussionModal(props) {
  const { isOpen, onClose } = props;
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('토론 주제를 입력해 주세요.'),
    code: Yup.string().required('입장 코드를 입력해 주세요.').length(6, '6자리 입장 코드를 입력해 주세요.'),
  });
  const setRandomCode = (setFieldValue) => {
    const code = getRandomCode(6);
    setFieldValue('code', code);
  }
  const onSubmit = (values) => {
    setLoading(true);
    const id = values.code;
    firebase.firestore().collection('Discussions').doc(id)
      .set({
        id,
        title: values.title,
        notice: values.notice,
        createdAt: new Date(),
        members: [],
        messages: []
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>실시간 토론 만들기</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            code: '',
            title: '',
            notice: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue}) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <FormControl isInvalid={errors.code && touched.code} my="15px">
                  <FormLabel htmlFor="code" fontsize="13px" fontWeight="bold">입장코드</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children="3" />
                    <Input
                      id="code"
                      name="code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                    />
                    <InputRightElement w="6em">
                      <Button w="6em" onClick={() => setRandomCode(setFieldValue)} variantColor="teal">자동생성</Button>
                    </InputRightElement>
                  </InputGroup>
                  {errors.code && touched.code && <FormErrorMessage>{errors.code}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.title && touched.title} my="15px">
                  <FormLabel htmlFor="title" fontsize="13px" fontWeight="bold">토론주제</FormLabel>
                  <Textarea
                    id="title"
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {errors.title && touched.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.notice && touched.notice} my="15px">
                  <FormLabel htmlFor="title" fontsize="13px" fontWeight="bold">공지사항</FormLabel>
                  <Textarea
                    id="notice"
                    name="notice"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.notice}
                  />
                  {errors.notice && touched.notice && <FormErrorMessage>{errors.notice}</FormErrorMessage>}
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button type="button" variantColor="blue" mr={3} onClick={onClose}>
                  취소
                </Button>
                <Button type="submit" variant="ghost" isLoading={loading}>확인</Button>
              </ModalFooter>
            </form>
            )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}