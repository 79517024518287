import React, {
  useEffect, useState, useMemo,
} from 'react';
import firebase from 'firebase/app';
import { useParams } from 'react-router-dom';
import {
  AccordionItem,
  AccordionHeader,
  AccordionIcon, AccordionPanel,
  Avatar,
  Badge,
  Box,
  Heading,
  Text,
  Textarea,
  Icon,
  Flex,
  Button,
  Image,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/core';
import Loading from '../../Component/Loading';
import LightBox from './LightBox';
import dayjs from 'dayjs';
import { useUser } from '../../Hooks/useUser';
import 'dayjs/locale/ko';
import ScrollToBottom, {useScrollToBottom} from 'react-scroll-to-bottom';
import { css } from 'glamor'
import FileUploadModal from '../../Component/FileUploadModal';

const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);
dayjs.locale('ko');

const UserNameModal = ({name, setName}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    onOpen();
  }, [onOpen]);
  const onChange = (e) => {
    const {value} = e.target;
    if (value && value.length > 8) {
      return alert('8자 이하로 입력해 주세요.');
    }
    return setName(value);
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>토론 입장</ModalHeader>
          <ModalBody>
            <Box>
              <Input placeholder="이름을 입력해 주세요." value={name} onChange={onChange} />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variantColor="blue" mr={3} onClick={onClose} disabled={!name}>
              확인
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default function Discussion(props) {
  const {fullScreen} = props;
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [text, setText] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lightBoxURL, setLightBoxURL] = useState(null);
  const user = useUser();
  const docRef = useMemo(() => firebase.firestore().collection('Discussions').doc(id), [id]);
  const scrollToBottom = useScrollToBottom();
  const ScrollContainerCSS = css({
    marginTop: '45px',
    height: `calc(100vh - 60px - ${fullScreen ? '120px' : '175px'})`,
    width: '100%'
  });
  useEffect(() => {
    if (user) {
      docRef.get().then((snapshot) => {
        if (snapshot && snapshot.exists) {
          const doc = snapshot.data();
          if (!doc.members[user.id]) {
            docRef.set({
              members: {
                [user.id]: {
                  userId: user.id,
                  createdAt: new Date(),
                  ...user,
                },
              },
            }, { merge: true });
          }
        }
      });
    }
  }, [docRef, user]);
  useEffect(() => {
    const subscribe = docRef.onSnapshot((snapshot) => {
      if (snapshot && snapshot.exists) {
        setData(prev => ({ ...prev, id: snapshot.id, ...snapshot.data() }));
      }
      setLoading(false);
    });
    return subscribe;
  }, [docRef]);
  const messages = useMemo(() => {
    if (!data || !user) return null;
    return data.messages.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return 1;
      }
      return -1;
    }).map((message) => ({
      ...message,
      id: message.id,
      date: message.createdAt.toDate(),
      type: message.type,
      text: message.text,
      position: message.userId === user.id ? 'right' : 'left',
      createdAt: message.createdAt.toDate(),
      timeString: dayjs(message.createdAt.toDate()).format('LT'),
      userName: message.userName,
    }));
  }, [data, user]);
  const onSendText = () => {
    if (!text) return;
    const key = Math.random().toString(36).substr(2, 11);
    const newMessage = {
      id: key,
      createdAt: new Date(),
      type: 'text',
      text,
      userId: user.id,
      userName
    };
    docRef.update({
      messages: firebase.firestore.FieldValue.arrayUnion(newMessage),
    })
      .then(() => {
        setText('');
      })
      .catch((error) => console.error(error));
  };
  const onFileUploaded = async (result) => {
    const {
      metadata: {
        fullPath, name, contentType, ref,
      },
    } = result;
    const key = Math.random().toString(36).substr(2, 11);
    const downloadURL = await ref.getDownloadURL();
    const newMessage = {
      id: key,
      createdAt: new Date(),
      type: contentType.includes('image') ? 'image' : 'file',
      text: '',
      userId: user.id,
      path: fullPath,
      URL: downloadURL,
      fileName: name,
      userName,
    };
    docRef.update({
      messages: firebase.firestore.FieldValue.arrayUnion(newMessage),
    })
    .then(() => {
      onClose();
    })
    .catch((error) => console.error(error));
  }
  useEffect(() => {
    if (messages && messages.length) {
      scrollToBottom();
    }
  }, [scrollToBottom, messages]);
  return (loading || !messages) ? <Loading fullScreen /> : (
    <Box 
      w="100%"
      maxW={fullScreen ? '100%' : '900px'}
      position="relative"
      pb="50px" pt="5%" px="5%"
    >
      <UserNameModal name={userName} setName={setUserName} />
      <Heading textAlign="center" my="5px" fontSize="18px">{data.title}</Heading>
      {(data.hostImage && data.hostImage.URL) && (
          <Box d="flex" justifyContent="center" alignItems="center" my="10px">
            <Image src={data.hostImage.URL} height="50px" marginLeft="6px" />
          </Box>
        )}
      <Box rounded="lg" overflow="hidden" backgroundColor="gray.50" position="absolute" zIndex="1" width="100%">
        <AccordionItem defaultIsOpen={false} border="none">
          <AccordionHeader border="none">
            <Text flex="1" textAlign="left" fontWeight="bold" fontSize="14px">
              토론 규칙
            </Text>
            <AccordionIcon color="gray.500" />
          </AccordionHeader>
          <AccordionPanel pb={4}>
            <Text color="gray.500" fontSize="14px">{data.notice}</Text>
          </AccordionPanel>
        </AccordionItem>
      </Box>
      <ScrollToBottom className={ScrollContainerCSS}>
        {messages.map((message) => (
          <Box
            key={message.id}
            d="flex"
            flexDirection={message.position === 'right' ? 'row-reverse' : 'row'}
            my="10px"
          >
            <Box
              m={message.position === 'right' ? '0 0 0 6px' : ' 0 6px 0 0'}
            >
              {message.position !== 'right' && (
                <Avatar
                  name={message.userName} 
                  size="sm"
                />
              )}
            </Box>
            <Box maxW="60%" fontSize="0.875rem" lineHeight="140%" whiteSpace="pre-line">
              {message.position !== 'right' && <Text fontWeight="bold" mb="4px">{message.userName}</Text>}
              <Box
                w="100%"
                d="inline-block"
                p="12px"
                backgroundColor={message.position === 'right' ? 'blue.500' : 'gray.100'}
                borderTopLeftRadius={message.position === 'right' ? 'lg' : 0}
                borderTopRightRadius="lg"
                borderBottomLeftRadius="lg"
                borderBottomRightRadius={message.position === 'right' ? 0 : 'lg'}
              >
                {message.type === 'image' && (
                  <>
                    <Image src={message.URL} rounded="lg" onClick={() => setLightBoxURL(message.URL)} maxH="250px" />
                    <LightBox onClose={() => setLightBoxURL('')} isOpen={!!lightBoxURL} URL={lightBoxURL} />
                  </>
                )}
                {message.type === 'file' && (
                  <Badge
                    variantColor="blue"
                    p="10px"
                    as="a" href={message.URL}
                    rounded="lg"
                    w="100%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {message.fileName}
                  </Badge>
                )}
                {message.text && <Text color={message.position === 'right' ? '#FFF' : 'black'}>{message.text}</Text>}
              </Box>
            </Box>
            <Box d="flex" flexDirection="column" justifyContent="flex-end">
              <Text
                fontSize="0.7rem"
                color="gray.400"
                m={message.position === 'right' ? '0 5px 0 0' : '0 0 0 5px'}
              >
                {message.timeString}
              </Text>
            </Box>
          </Box>
        ))}
      </ScrollToBottom>
      {!fullScreen && (<Flex
          w="100%" 
          maxW="900px"
          alignItems="center"
          pt="5px"
          pb="10px"
          mx="auto"
          backgroundColor="#FFF"
        >
            <Icon name="add" onClick={onOpen} mx="10px" />
            <Textarea
              flex="1"
              minH="1em"
              pr="4.5rem"
              value={text}
              onChange={(event) => setText(event.target.value)}
              resize="none"
              backgroundColor="gray.100"
              rounded="lg"
            />
            <Button disabled={!text} onClick={onSendText} ml="4px" mr="10px" variantColor="blue" rounded="lg">전송</Button>
        </Flex>)}
      <FileUploadModal isOpen={isOpen} onClose={onClose} onFileUploaded={onFileUploaded} />
    </Box>
  );
}
