import React, {useMemo} from 'react';
import {
  Box,
  Tabs, TabList, Tab,
} from '@chakra-ui/core';
import {Route, Switch, Redirect, Link, useLocation} from 'react-router-dom';
import AdminDiscussion from './AdminDiscussion';
import AdminOnlinePoll from './AdminOnlinePoll';
import AdminPoll from './AdminPoll';
import OnlinePollDetail from './OnlinePollDetail';
import DiscussionDetail from './DiscussionDetail';
import PollDetail from './PollDetail';
import PollPresentationControl from './PollPresentationControl';

export default function Admin(props) {
  const location = useLocation();
  const tabIndex = useMemo(() => {
    const type = location && location.pathname && location.pathname.split('/')[2];
    if (!type) {
      return 0;
    } else if (type === '3') {
      return 1;
    } else if (type === '5') {
      return 2;
    } else {
      return 0;

    }
  }, [location]);
  return (
    <Box w="100%">
      <Route path="/admin">
        <Tabs index={tabIndex} isManual>
          <TabList>
            <Tab>
              <Link to="/admin/1/">
                온라인 투표
              </Link>
            </Tab>
            <Tab>
              <Link to="/admin/3/">
                실시간 토론
              </Link>
            </Tab>
            <Tab>
              <Link to="/admin/5">
                현장 투표
              </Link>
            </Tab>
          </TabList>
        </Tabs>
      </Route>
      <Switch>
        <Route path="/admin/1/:id">
          <OnlinePollDetail />
        </Route>
        <Route path="/admin/1/">
          <AdminOnlinePoll />
        </Route>
        <Route path="/admin/3/:id">
          <DiscussionDetail />
        </Route>
        <Route path="/admin/3/">
          <AdminDiscussion />
        </Route>
        <Route path="/admin/5/:id/presentation">
          <PollPresentationControl />
        </Route>
        <Route path="/admin/5/:id">
          <PollDetail />
        </Route>
        <Route path="/admin/5/">
          <AdminPoll />
        </Route>
        <Route path="/admin">
          <Redirect to="/admin/1/" />
        </Route>
      </Switch>
    </Box>
  )
}