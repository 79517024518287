import React, {useMemo} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from '@chakra-ui/core';
import {useTable, useFlexLayout} from 'react-table';

export default function AttendeeListModal(props) {
  const { isOpen, onClose, responsors } = props;
  const columns = useMemo(() => {
    return [
      {
        Header: '번호',
        Cell: ({row}) => row.index + 1,
        width: 150
      },
      {
        Header: '이름',
        accessor: 'displayName',
      },
      {
        Header: '닉네임',
        accessor: 'nickName',
      },
      {
        Header: '휴대전화',
        accessor: 'phoneNumber'
      },
      {
        Header: '생년월일',
        accessor: 'birth',
      },
      {
        Header: '성별',
        accessor: 'gender',
        Cell: ({row}) => {
          if (!row.original.gender) return '';
          if (row.original.gender === 'male') return '남성';
          if (row.original.gender === 'female') return '여성';
        }
      },
    ]
  }, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: responsors }, useFlexLayout);
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="full">
      <ModalOverlay />
      <ModalContent maxWidth="1200px">
        <ModalHeader>참가자 정보({responsors.length})</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box {...getTableProps()}>
            <Box>
              {// Loop over the header rows
              headerGroups.map(headerGroup => (
                // Apply the header row props
                <Box borderBottomWidth="1px" borderBottomColor="gray.300" py="8px" {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <Box d="flex" justifyContent="center" alignItems="center" {...column.getHeaderProps()}>
                      {// Render the header
                      column.render('Header')
                      }
                    </Box>
                  ))
                  }
                </Box>
              ))
              }
            </Box>
            {// Apply the table body props
            <Box {...getTableBodyProps()}>
              {// Loop over the table rows
              rows.map(row => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <Box {...row.getRowProps()}>
                    {// Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      return (
                        <Box py="6px" d="flex" justifyContent="center" alignItems="center" {...cell.getCellProps()}>
                          {// Render the cell contents
                          cell.render('Cell')
                          }
                        </Box>
                      )
                    })
                    }
                  </Box>
                )
              })
              }
            </Box>
            }
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>확인</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}