import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
} from '@chakra-ui/core';

export default function LightBox(props) {
  const { isOpen, onClose, URL } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="lg" p="10px">
        <ModalCloseButton />
        <ModalBody>
          <Image src={URL} rounded="lg" />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}