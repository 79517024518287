import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Badge,
  Input,
} from '@chakra-ui/core';
import dayjs from 'dayjs';
import Resizer from 'react-image-file-resizer';
import firebase from 'firebase/app';

export default function FileUploadModal(props) {
  const { isOpen, onClose, onFileUploaded, context } = props;
  const inputRef = useRef();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const onChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // 파일 용량 제한
      const selectedFile = e.target.files[0];
      console.log('file selected', selectedFile);
      const MAX_SIZE = 20971520;
      if (selectedFile.size > MAX_SIZE) {
        alert(`${MAX_SIZE / 1024 / 1024}MB 이하의 파일을 선택해 주세요.`)
      } else {
        setFile(e.target.files[0]);
      }
    }
  };
  const selectFile = () => {
    const node = inputRef.current;
    if (!node) return;
    node.click();
  };
  const onSuccess = (result) => {
    setLoading(false);
    setFile(null);
    console.log('uploaded', result);
    onFileUploaded(result, context);
  }
  const onError = (error) => {
    setLoading(false);
    setFile(null);
    alert('업로드 중 오류가 발생했습니다. 다시 시도해 주세요.');
    console.error(error);
  }
  const uploadFile = () => {
    if (!file) return;
    setLoading(true);
    const name = `${file.name}_${dayjs().format('YYYY-MM-DD-HH-mm-ss')}`;
    if (file.type.includes('image')) {
      // resize image
      Resizer.imageFileResizer(file, 1920, 1920, 'PNG', 100, 0, uri => {
        console.log('resized', uri);
        firebase.storage().ref(`images/${name}`).put(uri)
        .catch(onError)
        .then(onSuccess)
      }, 'blob')
    } else {
      firebase.storage().ref(`files/${name}`).put(file)
      .catch(onError)
      .then(onSuccess)
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>파일 업로드</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box d="flex">
            <Button onClick={selectFile}>파일 선택</Button>
            {file && (
              <Badge 
                ml={1}
                variantColor="blue"
                overflow="hidden"
                textOverflow="ellipsis"
                alignItems="center"
                fontSize="0.8rem"
                rounded="lg"
                p="10px"
              >
                {file.name}
              </Badge>
            )}
            <Input display="none" type="file" ref={inputRef} onChange={onChange} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variantColor="red" mr={3} onClick={onClose}>
            취소
          </Button>
          <Button isLoading={loading} variantColor="teal" disabled={!file} onClick={uploadFile}>업로드</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
