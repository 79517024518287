import React, {useEffect, useState, useMemo} from 'react';
import {
  Heading,
  Button,
  ButtonGroup,
  Box,
  Input,
  Text,
  Flex,
  Image,
  IconButton,
  Editable,
  EditableInput,
  EditablePreview,
  PseudoBox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Divider,
} from "@chakra-ui/core";
import firebase from 'firebase/app';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {MdDragHandle} from 'react-icons/md';
import {Link, useParams} from 'react-router-dom';
import Loading from '../../Component/Loading';
import FileUploadModal from '../../Component/FileUploadModal';
import { getRandomCode } from '../../Utils/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';

const CustomDateInput = ({value, onClick}) => (
  <Button onClick={onClick} variantColor="teal" mr="20px" size="sm" variant="outline">
    {value}
  </Button>
)

const EditableInputForm = (props) => {
  const {defaultValue, onSubmit} = props;
  /* Here's a custom control */
  const EditableControls = ({ isEditing, onSubmit, onCancel, onRequestEdit }) => {
    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton icon="check" onClick={onSubmit} size="sm" variantColor="teal" />
        <IconButton icon="close" onClick={onCancel} size="sm" />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton ml="10px" size="sm" icon="edit" onClick={onRequestEdit} variantColor="blue" />
      </Flex>
    );
  }
  return (
    <Editable
      defaultValue={defaultValue}
      fontSize="18px"
      lineHeight="130%"
      isPreviewFocusable={false}
      submitOnBlur={false}
      onSubmit={onSubmit}
      borderWidth="1px"
      borderColor="gray.200"
      rounded="lg"
      p="5px 10px"
      alignItems="center"
      flex="1"
      placeholder=""
    >
      {props => (
        <Flex justifyContent="space-between">
          <EditablePreview />
          <EditableInput onBlur={null} flex="1" placeholder="" />
          <EditableControls {...props} />
        </Flex>
      )}
    </Editable>
  );
}

const AddOptionModal = (props) => {
  const {isOpen, onClose, docId, questionId} = props;
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const onSubmit = () => {
    setLoading(true);
    firebase.firestore().collection('OnlinePolls').doc(docId)
      .set({
        questions: {
          [questionId]: {
            options: firebase.firestore.FieldValue.arrayUnion({
              id: getRandomCode(4),
              title,
              images: [],
            })
          }
        }
      }, {merge:true})
      .then(() => {
        setTitle('');
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>옵션 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontWeight="bold" mb="5px">옵션 제목</Text>
            <Input value={title} onChange={e => setTitle(e.target.value)} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variantColor="red" mr={3} onClick={onClose}>
            취소
          </Button>
          <Button isLoading={loading} variantColor="teal" disabled={!title} onClick={onSubmit}>추가</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const AddQuestionModal = (props) => {
  const {isOpen, onClose, docId, nextIndex} = props;
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const questionId = getRandomCode(4);
  const onSubmit = () => {
    setLoading(true);
    firebase.firestore().collection('OnlinePolls').doc(docId)
      .set({
        questions: {
          [questionId]: {
            id: questionId,
            title,
            selectMinCount: 1,
            selectMaxCount: 1,
            options: [],
            order: nextIndex,
          }
        }
      }, {merge:true})
      .then(() => {
        setTitle('');
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>질문 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontWeight="bold" mb="5px">질문 제목</Text>
            <Input value={title} onChange={e => setTitle(e.target.value)} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variantColor="red" mr={3} onClick={onClose}>
            취소
          </Button>
          <Button isLoading={loading} variantColor="teal" disabled={!title} onClick={onSubmit}>추가</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default function OnlinePollDetail(props) {
  const {id: docId} = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const {isOpen: isImageUploadOpen, onOpen: onImageUploadOpen, onClose: onImageUploadClose} = useDisclosure();
  const {isOpen: isHostImageUploadOpen, onOpen: onHostImageUploadOpen, onClose: onHostImageUploadClose} = useDisclosure();
  const {isOpen: isAddQuestionModalOpen, onOpen: onAddQuestionModalOpen, onClose: onAddQuestionModalClose} = useDisclosure();
  const docRef = useMemo(() => firebase.firestore().collection('OnlinePolls').doc(docId), [docId]);
  useEffect(() => {
    if (docId) {
      const subscribe = docRef
        .onSnapshot(snapshot => {
          if (snapshot && snapshot.exists) {
            setData({ id: snapshot.id, ...snapshot.data()});
          }
          setLoading(false);
        })
      return subscribe;
    }
  }, [docId, docRef]);
  const onImageUploaded = async (result) => {
    const {
      metadata: {
        fullPath, ref,
      },
    } = result;
    const downloadURL = await ref.getDownloadURL();
    const newImage = {
      URL: downloadURL,
      path: fullPath,
    }
    docRef.update({
      image: newImage,
    })
    .then(() => {
      onImageUploadClose();
    })
    .catch((error) => console.error(error));
  }
  const onHostImageUploaded = async (result) => {
    const {
      metadata: {
        fullPath, ref,
      },
    } = result;
    const downloadURL = await ref.getDownloadURL();
    const newImage = {
      URL: downloadURL,
      path: fullPath,
    }
    docRef.update({
      hostImage: newImage,
    })
    .then(() => {
      onHostImageUploadClose();
    })
    .catch((error) => console.error(error));
  }
  const onDeleteImageClick = () => {
    const confirm = window.confirm('질문 이미지를 삭제하시겠습니까?');
    if (confirm) {
      docRef.update({ image: {} })
        .then(() => {})
        .catch((error) => {
          alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
          console.error(error);
        });
    }
  }
  const onDeleteHostImageClick = () => {
    const confirm = window.confirm('주최자 이미지를 삭제하시겠습니까?');
    if (confirm) {
      docRef.update({ hostImage: {} })
        .then(() => {})
        .catch((error) => {
          alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
          console.error(error);
        });
    }
  }
  const onSubmit = (value, fieldName) => {
    console.log('onSubmit', value)
    setLoading(true);
    firebase.firestore().collection('OnlinePolls').doc(docId)
      .update({
        [fieldName]: value,
        updatedAt: new Date(),
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  
  const onDateChange = (date, field) => {
    if (field === 'endAt' && dayjs(date).isBefore(dayjs(data.startAt))) {
      return alert('시작일 이후 날짜를 선택해 주세요.');
    }
    docRef.update({[field]: field === 'endAt' ? dayjs(date).hour(23).minute(59).second(59).toDate(): date})
      .then(() => {
        console.log(`${field} changed`);
      })
      .catch((error) => {
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  const onStatusChange = (e) => {
    const status = e.target.value;
    docRef.update({status})
      .then(() => {
        console.log('status changed');
      })
      .catch((error) => {
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  }
  const onQuestionDragEnd = (result) => {
    const { destination, draggableId: questionId, } = result;
    if (!destination) return;  
    const questions = Object.values(data.questions)
    .filter(question => !!question)
    .sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      return -1;
    });
    const sourceIndex = questions.findIndex(question => question.id === questionId);
    const destinationIndex = questions.findIndex(question => question.order === destination.index);
    let nextIndex = sourceIndex;
    if (destinationIndex === 0) {
      nextIndex = questions[destinationIndex].order * 0.5;
    } else if (destinationIndex === questions.length - 1) {
      nextIndex = questions[destinationIndex].order + 1;
    } else {
      nextIndex = (questions[destinationIndex - 1].order + questions[destinationIndex].order) * 0.5;
    }
    docRef.set({
      questions: {
        [questionId]: {
          order: nextIndex,
        }
      }
    }, {merge: true})
    .then(() => {
      console.log('reordered')
    })
    .catch((error) => {
      alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
      console.error(error);
    })
  }
  return (loading || !data) ? <Loading fullScreen /> : (
    <Box maxW="900px" mx="auto" py="20px">
      <Link to="/admin/1/"><Button mb="20px">뒤로가기</Button></Link>
      <Heading>상세 정보 및 수정</Heading>
      <Box my="30px">
        <Text fontsize="13px" fontWeight="bold" mb="5px">진행 상태</Text>
        <Select value={data.status} onChange={onStatusChange}>
          <option value="ON">진행</option>
          <option value="PAUSE">중지</option>
          <option value="OFF">종료</option>
        </Select>
      </Box>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontsize="13px" fontWeight="bold">입장 코드</Text>
        <Text color="gray.400" fontWeight="bold">1{data.id}</Text>
      </Box>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontsize="13px" fontWeight="bold" mb="5px">투표 주제</Text>
        <EditableInputForm fieldName="title" defaultValue={data.title} onSubmit={(value) => onSubmit(value, 'title')} />
      </Box>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontWeight="bold">투표 기간</Text>
        <Flex>
          <Box>
            <Text fontSize="14px">시작 날짜</Text>
            <DatePicker
              dateFormat="yyyy.MM.dd"
              selected={data.startAt.toDate()}
              onChange={date => onDateChange(date, 'startAt')}
              customInput={<CustomDateInput />}
            />
          </Box>
          <Box>
            <Text fontSize="14px">종료 날짜</Text>
            <DatePicker 
              dateFormat="yyyy.MM.dd"
              selected={data.endAt.toDate()}
              onChange={date => onDateChange(date, 'endAt')}
              customInput={<CustomDateInput />}
            />
          </Box>
        </Flex>
      </Box>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontsize="13px" fontWeight="bold" mb="5px">투표취지</Text>
        <EditableInputForm fieldName="description" defaultValue={data.description} onSubmit={(value) => onSubmit(value, 'description')} />
      </Box>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontWeight="bold" mb="5px">이미지</Text>
        <Box my="10px">
          <PseudoBox onClick={onImageUploadOpen} d="flex" alignItems="center" justifyContent="center" position="relative" _hover={{ borderWidth: '2px', borderColor: 'pink.400', cursor: 'pointer'}} height="250px" width="250px" rounded="lg" backgroundColor="gray.50">
            {data.image && data.image.URL ? (
              <>
                <Image src={data.image.URL} width="100%" height="100%" objectFit="cover" rounded="lg" />
                <IconButton icon="close" size="xs" position="absolute" top={-5} right={-5} variantColor="pink" onClick={onDeleteImageClick} />
              </>
            ) : (
              <Button size="sm" leftIcon="add">이미지 추가</Button>
            )}
            <FileUploadModal isOpen={isImageUploadOpen} onClose={onImageUploadClose} onFileUploaded={onImageUploaded} />
          </PseudoBox>
        </Box>
      </Box>
      <Box my="30px">
        <Text fontWeight="bold" mb="5px">주최자 로고</Text>
        <Box my="10px">
          <PseudoBox onClick={onHostImageUploadOpen} d="flex" alignItems="center" justifyContent="center" position="relative" _hover={{ borderWidth: '2px', borderColor: 'pink.400', cursor: 'pointer'}} height="50px" width="95%" rounded="lg" backgroundColor="gray.50">
            {data.hostImage && data.hostImage.URL ? (
              <>
                <Image src={data.hostImage.URL} width="100%" height="100%" objectFit="contain" rounded="lg" />
                <IconButton icon="close" size="xs" position="absolute" top={-5} right={-25} variantColor="pink" onClick={onDeleteHostImageClick} />
              </>
            ) : (
              <Button size="sm" leftIcon="add">이미지 추가</Button>
            )}
            <FileUploadModal isOpen={isHostImageUploadOpen} onClose={onHostImageUploadClose} onFileUploaded={onHostImageUploaded} />
          </PseudoBox>
        </Box>
      </Box>
      <Divider my="10px" />
      <Box mt="20px">
        <Text fontsize="15px" fontWeight="bold" mb="5px">질문</Text>
        <DragDropContext onDragEnd={onQuestionDragEnd}>
          <Droppable droppableId="questions">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {Object.values(data.questions)
                  .filter(question => !!question)
                  .sort((a, b) => {
                    if (a.order > b.order) return 1;
                    return -1;
                  })
                  .map((question) => (
                    <Draggable key={question.id} draggableId={question.id} index={question.order}>
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Questions questionId={question.id} data={question} docId={docId} />
                        </Box>
                      )}
                    </Draggable>
                  ))}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <Button size="lg" variantColor="teal" width="100%" mt="30px" onClick={onAddQuestionModalOpen}>질문 추가</Button>
        <AddQuestionModal docId={docId} isOpen={isAddQuestionModalOpen} onClose={onAddQuestionModalClose} nextIndex={Object.values(data.questions).length + 1} />
      </Box>
    </Box>
  )
}

const Questions = ({docId, questionId, data}) => {
  const {isOpen: isAddOptionOpen, onOpen: onAddOptionOpen, onClose: onAddOptionClose} = useDisclosure();
  const [isOptionImageUploadOpen, setIsOptionImageUploadOpen] = useState(false);
  const [optionIndexToUploadOptionImage, setOptionIndexToUploadOptionImage] = useState(null);
  const docRef = useMemo(() => firebase.firestore().collection('OnlinePolls').doc(docId), [docId]);
  const onDragEnd = (result, index) => {
    if (!result || !result.destination) {
      return;
    }
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
    
      return result;
    };
    let newOptions = Array.from(data.options);
    if (index >= 0) {
      const images = reorder(
        data.options[index].images,
        result.source.index,
        result.destination.index
      );
      newOptions[index].images = images;
    } else {
      newOptions = reorder(
        data.options,
        result.source.index,
        result.destination.index
      )
    }
    docRef.set({
      questions: {
        [questionId]: {
          options: newOptions
        },
      }, 
    }, {merge:true})
    .then(() => {
      console.log('reordered')
    })
    .catch((error) => {
      alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
      console.error(error);
    })
  }
  const onOptionImageUploaded = async (result, context) => {
    const {
      metadata: {
        fullPath, ref,
      },
    } = result;
    const downloadURL = await ref.getDownloadURL();
    const newOptions = Array.from(data.options);
    const {optionIndex} = context;
    newOptions[optionIndex].images.push({
      id: getRandomCode(4),
      URL: downloadURL,
      path: fullPath,
    })
    docRef.set({
      questions: {
        [questionId]: {
          options: newOptions,
        }
      }
    }, {merge:true})
    .then(() => {
      setIsOptionImageUploadOpen(false);
    })
    .catch((error) => console.error(error));
  }
  const onOptionImageDeleteClick = (optionIndex, imageIndex) => {
    const confirm = window.confirm('질문 이미지를 삭제하시겠습니까?');
    if (confirm) {
      const newOptions = Array.from(data.options);
      newOptions[optionIndex].images.splice(imageIndex, 1);
      docRef.set({
        questions: {
          [questionId]: {
            options: newOptions,
          }
        }
      }, {merge:true})
      .then(() => {
        console.log('removed option image')
      })
      .catch((error) => {
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
    }
  }
  const onOptionTitleSubmit = (value, optionIndex) => {
    const newOptions = Array.from(data.options);
    newOptions[optionIndex].title = value;
    docRef.set({
      questions: {
        [questionId]: {
          options: newOptions,
        }
      }
    }, {merge:true})
      .then(() => {
        console.log('removed option image')
      })
      .catch((error) => {
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  const openOptionImageUploadModal = (index) => {
    setOptionIndexToUploadOptionImage(index);
    setIsOptionImageUploadOpen(true);
  };
  const closeOptionImageUploadModal = () => {
    setOptionIndexToUploadOptionImage(null);
    setIsOptionImageUploadOpen(false);
  };
  const onSelectMinCountChange = (value) => {
    if (value < 1) {
      return alert('0 보다 큰 수를 선택해 주세요.')
    }
    if (value > data.selectMaxCount) {
      return alert('최대 선택 개수 보다 작은 수를 선택해 주세요.')
    }
    docRef.set({
      questions: {
        [questionId]: {
          selectMinCount: value,
        }
      }
    }, {merge:true})
      .then(() => {
        console.log('selectCount changed');
      })
      .catch((error) => {
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  const onSelectMaxCountChange = (value) => {
    if (value < 1) {
      return alert('0 보다 큰 수를 선택해 주세요.')
    }
    if (value < data.selectMinCount) {
      return alert('최소 선택 개수 보다 큰 수를 선택해 주세요.')
    }
    docRef.set({
      questions: {
        [questionId]: {
          selectMaxCount: value,
        }
      }
    }, {merge:true})
    .then(() => {
      console.log('selectCount changed');
    })
    .catch((error) => {
      alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
      console.error(error);
    })
  };
  const onTitleChange = value => {
    docRef.set({
      questions: {
        [questionId]: {
          title: value,
        }
      }
    }, {merge: true})
    .then(() => {
      console.log('question title changed');
    })
    .catch((error) => {
      alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
      console.error(error);
    })
  };
  const onQuestionDelete = () => {
    const confirm = window.confirm('질문을 삭제하시겠습니까?');
    if (confirm) {
      docRef.set({
        questions: {
          [questionId]: null
        }
      }, {merge: true})
        .then(() => {})
        .catch((error) => {
          alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
          console.error(error);
        });
    }
  }
  const onOptionDelete = optionId => {
    const confirm = window.confirm('옵션을 삭제하시겠습니까?');
    if (confirm) {
      const newOptions = Array.from(data.options);
      const optionIndex = newOptions.findIndex(option => option.id === optionId);
      newOptions[optionIndex].deleted = true;
      docRef.set({
        questions: {
          [questionId]: {
            options: newOptions,
          }
        }
      }, {merge:true})
        .then(() => {})
        .catch((error) => {
          alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
          console.error(error);
        });
    }
  }
  return (
    <Box my="20px" borderColor="gray.100" borderWidth="2px" rounded="lg" backgroundColor="#FFF">
      <Box d="flex" flex="1" justifyContent="center" py="5px" backgroundColor="gray.200" borderTopLeftRadius="lg" borderTopRightRadius="lg">
        <MdDragHandle />
      </Box>
      <Box p="15px">
        <Flex justifyContent="flex-end" mb="10px">
          <Button ml="5px" variantColor="red" size="sm" onClick={onQuestionDelete}>질문 삭제</Button>
        </Flex>
        <Text fontWeight="bold" mb="5px">질문</Text>
        <EditableInputForm defaultValue={data.title} onSubmit={onTitleChange} />
        <Box my="30px">
          <Text fontWeight="bold">옵션 선택 개수</Text>
          <Flex>
            <Box>
              <Text fontSize="14px">최소 개수</Text>
              <NumberInput size="sm" value={data.selectMinCount} onChange={onSelectMinCountChange}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
            <Box ml="10px">
              <Text fontSize="14px">최대 개수</Text>
              <NumberInput size="sm" value={data.selectMaxCount} onChange={onSelectMaxCountChange}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          </Flex>
        </Box>
        <DragDropContext onDragEnd={result => onDragEnd(result)}>
          <Droppable droppableId="options">
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {data.options && data.options
                  .filter(option => !option.deleted)
                  .map((option, optionIndex) => (
                    <Draggable key={option.id} draggableId={option.id} index={optionIndex}>
                      {(provided, snapshot) => (
                        <>
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          mb="10px"
                          p="5px"
                          rounded="lg"
                          backgroundColor="gray.50"
                        >
                          <Box d="flex" flex="1" justifyContent="center" py="5px">
                            <MdDragHandle />
                          </Box>
                          <Flex mb="15px" justifyContent="flex-end">
                            <Button variantColor="red" size="sm" onClick={() => onOptionDelete(option.id)}>옵션 삭제</Button>
                          </Flex>
                          <Flex mb="5px" alignItems="center" w="100%"> 
                            <Button size="md" variantColor="blue">{optionIndex + 1}</Button>
                            <EditableInputForm defaultValue={data.options[optionIndex].title} onSubmit={value => onOptionTitleSubmit(value, optionIndex)} />
                          </Flex>
                          <DragDropContext onDragEnd={result => onDragEnd(result, optionIndex)}>
                            <Droppable droppableId={`options.${optionIndex}.images`} direction="horizontal">
                              {(provided, snapshot) => (
                                <Box
                                  ref={provided.innerRef}
                                  d="flex" 
                                  flexWrap="wrap"
                                  {...provided.droppableProps}
                                >
                                  {option.images.map((image, optionImageIndex) => image && (
                                    <Draggable key={image.id} draggableId={image.id} index={optionImageIndex}>
                                      {(provided, snapshot) => (
                                        <Box
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          position="relative"
                                        >
                                          <Image src={image.URL} width="70px" height="70px" objectFit="cover" rounded="lg" mr="18px"/>
                                          <IconButton icon="close" size="xs" position="absolute" top={0} right={2} variantColor="pink" onClick={() =>  onOptionImageDeleteClick(optionIndex, optionImageIndex)} />
                                        </Box>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                  <IconButton w="70px" h="70px" icon="add" onClick={() => openOptionImageUploadModal(optionIndex)} />
                                </Box>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Box>
                        </>
                      )}
                    </Draggable>
                  ))}
                  <FileUploadModal context={{optionIndex: optionIndexToUploadOptionImage}} isOpen={isOptionImageUploadOpen} onClose={closeOptionImageUploadModal} onFileUploaded={onOptionImageUploaded} />
              </Box>
            )}
          </Droppable>
          <Button onClick={onAddOptionOpen} leftIcon="add" w="100%" size="sm">옵션 추가</Button>
          <AddOptionModal isOpen={isAddOptionOpen} onClose={onAddOptionClose} docId={docId} questionId={questionId} />
        </DragDropContext>
      </Box>
    </Box>
  )
}