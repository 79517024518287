import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import {
  Switch,
  Route,
  Redirect,
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useUser } from './Hooks/useUser';
import { Box, Button, Text, Divider, Image } from '@chakra-ui/core';
import Loading from './Component/Loading';
import Index from './Screens/Index';
import SignIn from './Screens/SignIn';
import Profile from './Screens/Profile';
import Discussion from './Screens/Discussion';
import AdminSignIn from './Screens/AdminSignIn';
import Admin from './Screens/Admin';
import OnlinePoll from './Screens/OnlinePoll';
import Poll from './Screens/Poll';
import PollPresentation from './Screens/Poll/PollPresentation';
import DiscussionPresentation from './Screens/Discussion/DiscussionPresentation';

function App() {
  const [auth, setAuth] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const user = useUser();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user && history) {
        setAuth(null);
        setLoading(false);
      } else {
        firebase.firestore().collection('Users').doc(user.uid).get().then(doc => {
          if (doc && doc.exists) {
            const data = doc.data();
            setIsAdmin(!!data.isAdmin);
            setAuth(user);
            setLoading(false);
          } else {
            setAuth(user);
            setLoading(false);
          }
        })
      }
    });
  }, [history]);
  const AdminRoute = ({ children, ...rest }) => (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAdmin) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/signin/admin',
                state: { from: location }
              }}
            />
          )
        }
      }}
    />
  );
  const PrivateRoute = ({ children, ...rest }) => (
    <Route
      {...rest}
      render={({ location }) => {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          return children
        } else {
          firebase.auth().signInAnonymously()
            .then(() => {
              console.log('sign in anonymously');
              return children;
            })
            .catch((error) => {
              console.error('error @signIn', error.code);
              console.error('error @signIn', error.message);
              return (
                <Redirect
                  to="/"
                />
              )
            })
        }
      }}
    />
  );
  const toProfile = () => {
    history.push('/profile', { from: location.pathname });
  };
  const signOut = () => {
    firebase.auth().signOut();
    history.push('/');
  }
  return (
    <Box d="flex" flexDirection="column" h="100vh">
      <header>
        <Box d="flex" justifyContent="space-between" mx="auto" h="40px" w="100%" px="4%" alignItems="center">
          <Box d="flex" alignItems="flex-end" justifyContent="center">
            <Link to="/">
              <Image src="/images/logo.png" alt="logo" height="25px" />
            </Link>
            {location.pathname.includes('admin') && <Text fontWeight="bold" fontSize="14px" ml="5px">관리자</Text>}
          </Box>
          {auth && user && !location.pathname.includes('presentation') && (
            <Box d="flex" alignItems="center">
              {user.nickName && (
                <>
                  <Button size="xs" ml="10px" onClick={toProfile} variant="ghost" variantColor="blue">
                    <Text fontWeight="bold" mr="0.2em">{user.nickName}</Text>님
                  </Button>
                  <Button size="xs" ml="10px" onClick={signOut}>로그아웃</Button>
                </>
              )}
            </Box>
          )}
        </Box>
      </header>
      <Divider my="0" />
      <Box width="100%" h="calc(100vh - 40px - 1px)" overflow="auto" flex="1">
        <Box d="flex" justifyContent="center" w="100%" h="100%">
          {loading ? <Loading fullScreen /> : (
            <Switch>
              <PrivateRoute path="/1/:id">
                <OnlinePoll />
              </PrivateRoute>
              <PrivateRoute path="/3/:id/presentation">
                <DiscussionPresentation />
              </PrivateRoute>
              <PrivateRoute path="/3/:id">
                <Discussion />
              </PrivateRoute>
              <PrivateRoute path="/5/:id/presentation">
                <PollPresentation />
              </PrivateRoute>
              <PrivateRoute path="/5/:id">
                <Poll />
              </PrivateRoute>
              <PrivateRoute path="/profile">
                <Profile />
              </PrivateRoute>
              <AdminRoute path="/admin">
                <Admin />
              </AdminRoute>
              <Route exact path="/signin/admin">
                <AdminSignIn />
              </Route>
              <Route exact path="/signin">
                <SignIn />
              </Route>
              <Route exact path="/">
                <Index />
              </Route>
            </Switch>
          )}
        </Box>
      </Box>
      <Divider my="0" />
    </Box>
  );
}

export default App;
