import React, {useEffect, useState, useRef} from 'react';
import firebase from 'firebase/app';
import {Box, Input, InputGroup, InputLeftElement, InputRightElement, Icon, Button, Text, Heading} from "@chakra-ui/core";
import {useHistory} from 'react-router-dom';

export default function SignIn(props) {
  const history = useHistory();
  const reCapchaRef = useRef(null);
  const [sentSMS, setSentSMS] = useState(false);
  useEffect(() => {
    if (reCapchaRef && reCapchaRef.current) {
      firebase.auth().useDeviceLanguage();
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(reCapchaRef.current, {size: 'invisible'});
      window.recaptchaVerifier.render().then(function(widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
    }
  }, [reCapchaRef]);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const verifyPhone = () => {
    const appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(`+82${phone}`, appVerifier).then(function (confirmationResult) {
      // SMS sent. Promt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      setSentSMS(true);
      window.confirmationResult = confirmationResult;
      console.log(`SMS Code sent to phone: ${phone}`, confirmationResult)
    }).catch(function (error) {
      // Error; SMS not sent
      console.error('@signInWithPhoneNumber :', error);
      if (error.code === 'auth/invalid-phone-number') {
        alert('전화번호를 다시 확인해 주세요.');
      } else if (error.code === 'auth/internal-error') {
        alert('발신 한도를 초과하였습니다. 잠시 후 다시 이용해 주세요.')
      }
    })
  }
  const verifyCode = () => {
    window.confirmationResult.confirm(code).then(() => {
      console.log('Code verified', phone);
      const from = history && history.location && history.location && history.location.state && history.location.state.from && history.location.state.from.pathname;
      console.log('go to profile and ', from);
      history.push('/profile', {from});
    }).catch(error => {
      console.log(error);
      if (error.code === 'auth/code-expired') {
        alert('인증코드가 만료되었습니다. 인증코드를 다시 발송해 주세요.');
      } else if (error.code === 'auth/missing-verification-code') {
        alert('인증코드를 입력해 주세요.');
      } else if (error.code === 'auth/invalid-verification-code') {
        alert('인증코드를 다시 확인해 주세요.');
      }
    });
  }
  return (
    <Box w="100%" maxW="600px">
      <div ref={reCapchaRef} />
      <Heading textAlign="center" mb="10px">인 증</Heading>
      <Box rounded="lg" backgroundColor="gray.100" px="8px" py="15px">
        <Text fontSize="13px" px="10px" mb="10px" lineHeight="120%" wordBreak="keep-all" textAlign="center">
          * 토론 및 투표의 통계를 위해 간단한 정보를 입력해 주세요.
        </Text>
        <InputGroup w="100%">
          <InputLeftElement children={<Icon name="phone" color="gray.300" />} />
          <Input onChange={event => setPhone(event.target.value)} value={phone} placeholder="01012341234" />
          <InputRightElement w="7em" children={<Button w="7em" disabled={!phone} onClick={verifyPhone} size="sm">인증번호 발송</Button>} />
        </InputGroup>
        {sentSMS && (
          <>
          <InputGroup w="100%" mt="5px">
            <InputLeftElement children={<Icon name="lock" color="gray.300" />} />
            <Input placeholder="123456" onChange={event => setCode(event.target.value)} value={code} />
          </InputGroup>
          <Button w="100%" disabled={!code} onClick={verifyCode} variantColor="blue" mt="10px">인 증</Button>
          </>
        )}
      </Box>
    </Box>
  )
}