import React, {useRef, useEffect, useState, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import firebase from 'firebase/app';
import {
  Box,
  Input,
  Button,
  FormLabel,
  FormControl,
  FormErrorMessage,
  RadioButtonGroup,
  Text,
  Flex,
} from "@chakra-ui/core";
import {FiAlertCircle} from 'react-icons/fi';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Loading from '../../Component/Loading';

const CustomRadio = React.forwardRef((props, ref) => {
  const { isChecked, isDisabled, value, ...rest } = props;
  return (
    <Button
      width="48%"
      ref={ref}
      variantColor={isChecked ? "blue" : "gray"}
      aria-checked={isChecked}
      role="radio"
      isDisabled={isDisabled}
      {...rest}
    />
  );
});

export default function Profile (props) {
  const history = useHistory();
  const from = useRef(history && history.location && history.location && history.location.state && history.location.state.from).current;
  const {
    currentUser: {
      uid,
      phoneNumber,
    }
  } = firebase.auth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const userRef = useMemo(() => firebase.firestore().collection('Users').doc(uid), [uid]);
  useEffect(() => {
    userRef.get().then(snapshot => {
      if (snapshot && snapshot.exists) {
        setUser({ id: snapshot.id, ...snapshot.data(), });
      }
      setLoading(false);
    })
  }, [userRef]);
  const goBack = () => history.goBack();
  const onSubmit = (values) => {
    userRef.set(values, {merge: true})
    .then(() => {
      console.log('profile updated. move to', from);
      history.push((!from || from === '/profile') ? '/' : from);
    })
    .catch(error => console.error(error));
  }
  useEffect(() => {
    if (from) {
      const [_, serviceType, docId] = from.split('/');
      if (serviceType === '5') {
        firebase.firestore().collection('Polls').doc(docId).get().then(doc => {
          if (doc && doc.exists) {
            const data = doc.data();
            if (data.isAnonymous) {
              onSubmit({
                phoneNumber: phoneNumber || '',
                displayName: '',
                nickName: '',
                birth: '',
                gender: null,
              })
            }
          }
        })
      }
    }
  }, [from, phoneNumber])
  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required('이름을 입력해 주세요.'),
    nickName: Yup.string().required('닉네임을 입력해 주세요.'),
    birth: Yup.string().required('생년월일을 입력해 주세요.').length(8, '생년월일을 다시 확인해 주세요.'),
    gender: Yup.string().required('성별을 입력해 주세요.')
  });
  return loading ? <Loading fullScreen /> : (
    <Box mt="20px">
      <Text mb="20px" fontSize="1.3rem">토론 입장</Text>
      <Flex alignItems="center">
        <FiAlertCircle style={{height: '18px', width: '18px', padding: '1px 0 2px', color: "#3182ce", marginRight: '3px'}} />
        <Text fontSize="13px" my="5px">토론 및 투표의 통계를 위해 간단한 정보를 입력해 주세요.</Text>
      </Flex>
      <Box py="15px">
        <Formik
          initialValues={{
            phoneNumber: phoneNumber || (user.phoneNumber || ''),
            displayName: (user && user.displayName) ? user.displayName : '',
            nickName: (user && user.nickName) ? user.nickName : '',
            birth: (user && user.birth) ? user.birth : '',
            gender: (user && user.gender) ? user.gender : '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({values, errors, touched, setFieldValue, handleChange, handleSubmit, handleBlur }) => (
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={errors.phoneNumber && touched.phoneNumber}  my="10px">
                <FormLabel htmlFor="phoneNumber" fontSize="14px" fontWeight="bold">전화번호</FormLabel>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  aria-describedby="phoneNumber-helper-text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  disabled
                />
                {errors.phoneNumber && touched.phoneNumber && <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={errors.displayName && touched.displayName}  my="10px">
                <FormLabel htmlFor="displayName" fontSize="14px" fontWeight="bold">이름</FormLabel>
                <Input
                  id="displayName"
                  name="displayName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.displayName}
                />
                {errors.displayName && touched.displayName && <FormErrorMessage>{errors.displayName}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={errors.nickName && touched.nickName} my="10px">
                <FormLabel htmlFor="nickName" fontSize="14px" fontWeight="bold">닉네임</FormLabel>
                <Input
                  id="nickName"
                  name="nickName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nickName}
                />
                {errors.nickName && touched.nickName && <FormErrorMessage>{errors.nickName}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={errors.birth && touched.birth} my="10px">
                <FormLabel htmlFor="birth" fontSize="14px" fontWeight="bold">생년월일</FormLabel>
                <Input
                  id="birth"
                  name="birth"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.birth}
                  placeholder="예) 19800518"
                />
                {errors.birth && touched.birth && <FormErrorMessage>{errors.birth}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={errors.gender && touched.gender} my="10px">
                <FormLabel htmlFor="gender" fontSize="14px" fontWeight="bold">성별</FormLabel>
                <RadioButtonGroup
                  id="gender"
                  name="gender"
                  onChange={value => setFieldValue('gender', value)}
                  onBlur={handleBlur}
                  defaultValue={values.gender}
                  isInline
                >
                  <CustomRadio value="male">남성</CustomRadio>
                  <CustomRadio value="female">여성</CustomRadio>
                </RadioButtonGroup>
                {errors.gender && touched.gender && <FormErrorMessage>{errors.gender}</FormErrorMessage>}
              </FormControl>
              <Flex alignItems="center">
                <FiAlertCircle style={{height: '18px', width: '18px', padding: '1px 0 2px', color: "#3182ce", marginRight: '3px'}} />
                <Text fontSize="13px" my="5px">투표 마감 이후 개인 정보는 삭제됩니다.</Text>
              </Flex>
              <Box d="flex" justifyContent="space-around" my="15px">
                <Button type="button" onClick={goBack} variantColor="gray" w="6em" mr="10px">취 소</Button>
                <Button type="submit" variantColor="blue" flex="1">확인</Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}