import React, { useState } from 'react';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { Box, Input, Button, Image, Text } from "@chakra-ui/core";

export default function Index() {
  const [code, setCode] = useState('');
  const history = useHistory();
  const onSubmit = () => {
    // 1. 핀 번호가 없음
    if (!code) {
      console.log('empty')
      return window.alert('입장 코드를 입력해 주세요.')
    }
    const initialCharacter = code[0].toLowerCase();
    const id = code.slice(1);
    const COLLECTION_CODE = {
      1: 'OnlinePolls',
      3: 'Discussions',
      5: 'Polls'
    }
    // 2. 올바르지 않은 핀 번호(시작값이 올바르지 않음)
    if (code.replace(/\s/g, '').length < 7 || !COLLECTION_CODE[initialCharacter]) {
      console.log('wrong code')
      return window.alert('입장 코드를 다시 확인해 주세요.')
    }
    // 3. 올바르지 않은 핀 번호(등록되지 않은 번호)
    firebase.firestore().collection(COLLECTION_CODE[initialCharacter]).doc(id).get().then(snapshot => {
      if (!snapshot.exists) {
        console.log('not exist')
        return window.alert('입장 코드를 다시 확인해 주세요.');
      }
      return history.push(`/${initialCharacter}/${id}`);
    })
  }

  const InputBox = (props) => (
    <Box width="40%" alignItems="center" {...props}>
      <Box d="flex" flexDirection="column" alignItems="center" backgroundColor="#FFF" marginX="auto">
        <Text fontSize={{ base: "18px", xl: "24px" }} fontWeight="600">투표장 입장을 위해</Text>
        <Text fontSize={{ base: "18px", xl: "24px" }} fontWeight="600">입장코드를 입력해 주십시오.</Text>
        <Box mt="40px" mb="20px" w="100%">
          <Input placeholder="입장 코드를 입력해 주세요" value={code} onChange={event => setCode(event.target.value)} h="50px" textAlign="center" />
          <Button onClick={onSubmit} disabled={!code} variantColor="blue" w="100%" size="lg" mt="15px" h="50px">입력완료</Button>
          <Text display={{ base: "none", xl: "block" }} color="gray.500" fontSize="13px" textAlign="center" marginTop="30px">문의 : 02-508-4737 / kim@isell.kr</Text>
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      <Box d={{ base: "none", xl: "flex" }} justifyContent="space-between" alignItems="center" width="100%">
        <Image src="/images/main_desktop.jpeg" width="60%" height="100vh" objectFit="cover" />
         <Box width="40%" alignItems="center" padding="10%">
          <Box d="flex" flexDirection="column" alignItems="center" backgroundColor="#FFF" marginX="auto">
            <Text fontSize={{ base: "18px", xl: "24px" }} fontWeight="600">투표장 입장을 위해</Text>
            <Text fontSize={{ base: "18px", xl: "24px" }} fontWeight="600">입장코드를 입력해 주십시오.</Text>
            <Box mt="40px" mb="20px" w="100%">
              <Input placeholder="입장 코드를 입력해 주세요" value={code} onChange={event => setCode(event.target.value)} h="50px" textAlign="center" />
              <Button onClick={onSubmit} disabled={!code} variantColor="blue" w="100%" size="lg" mt="15px" h="50px">입력완료</Button>
              <Text display={{ base: "none", xl: "block" }} color="gray.500" fontSize="13px" textAlign="center" marginTop="30px">문의 : 02-508-4737 / kim@isell.kr</Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box d={{ base: "flex", xl: "none" }} width="100%" flexDirection="column">
        <Box position="relative">
          <Box position="absolute" top="40vw" left="50%" width="95vw" height="95vw" paddingLeft="6%" paddingRight="6%" transform="translateX(-50%)" alignItems="center">
            <Box d="flex" flexDirection="column" alignItems="center" backgroundColor="#FFF" marginX="auto" p="7%">
              <Text fontSize={{ base: "18px", xl: "24px" }} fontWeight="600">투표장 입장을 위해</Text>
              <Text fontSize={{ base: "18px", xl: "24px" }} fontWeight="600">입장코드를 입력해 주십시오.</Text>
              <Box mt="40px" mb="20px" w="100%">
                <Input placeholder="입장 코드를 입력해 주세요" value={code} onChange={event => setCode(event.target.value)} h="50px" textAlign="center" />
                <Button onClick={onSubmit} disabled={!code} variantColor="blue" w="100%" size="lg" mt="15px" h="50px">입력완료</Button>
                <Text display={{ base: "none", xl: "block" }} color="gray.500" fontSize="13px" textAlign="center" marginTop="30px">문의 : 02-508-4737 / kim@isell.kr</Text>
              </Box>
            </Box>
          </Box>
          <Image src="/images/main_mobile.jpeg" width="100%" objectFit="cover" />
        </Box>
      </Box>
    </>
  )
}


