import React, {useState, useEffect, useMemo} from 'react';
import firebase from 'firebase/app';
import {Box, Text, Button, Link as A, useDisclosure, Flex, useToast} from '@chakra-ui/core';
import {Link} from 'react-router-dom';
import {useTable, useFlexLayout} from 'react-table';
import Loading from '../../Component/Loading';
import AddDiscussionModal from './AddDiscussionModal';
import AttendeeListModal from '../../Component/AttendeeListModal';
import dayjs from 'dayjs';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function AdminDiscussion(props) {
  const [docs, setDocs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [row, setRow] = useState(null);
  const toast = useToast();
  const { isOpen: isPeopleInfoOpen, onOpen: onOpenPeopleInfo, onClose: onClosePeopleInfo } = useDisclosure(); 
  const { isOpen: isAdditionOpen, onOpen: onOpenAddition, onClose: onCloseAddition} = useDisclosure();
  const openPeopleInfo = (value) => {
    setRow(value);
    onOpenPeopleInfo();
  };
  const closePeopleInfo = () => {
    setRow(null);
    onClosePeopleInfo();
  }
  useEffect(() => {
    const subscribe = firebase.firestore()
      .collection('Discussions')
      .orderBy('createdAt', 'desc')
      .onSnapshot(snapshots => {
        if (snapshots) {
          const dataToSet = [];
          snapshots.forEach(snapshot => {
            if (snapshot.exists) {
              dataToSet.push({ id: snapshot.id, ...snapshot.data(), });  
            }
          })
          setDocs(dataToSet);
        }
        setLoading(false);
      })
    return subscribe;
  }, []);
  const data = useMemo(() => {
    if (docs) {
      return docs.map(doc => ({
        ...doc,
        id: doc.id,
        code: `3${doc.id}`,
        title: doc.title,
        createdAt: doc.createdAt ? doc.createdAt.toDate() : null,
        createdAtString: doc.createdAt ? dayjs(doc.createdAt.toDate()).format('YYYY.MM.DD') : null,
        peopleCount: Object.keys(doc.members).length,
        members: Object.values(doc.members),
        link: `/3/${doc.id}`,
        notice: doc.notice,
      }))
    } else {
      return [];
    }
  }, [docs]);
  const deleteDiscussion = (docId) => {
    if (window.confirm(`3${docId} 실시간 토론을 삭제하시겠습니까?`)) {
      firebase.firestore().collection('Discussions').doc(docId).delete()
      .then(() => console.log('discussion deleted'))
      .catch(error => console.error('@delete discussion', error));
    }
  }
  const columns = useMemo(() => {
    return [
      {
        Header: '액션',
        accessor: 'id',
        Cell: ({row}) => (
          <Flex>
            <Button mr="8px" size="sm" variantColor="red" onClick={() => deleteDiscussion(row.original.id)}>삭제</Button>
            <Link to={`/admin/3/${row.original.id}`}><Button size="sm">수정</Button></Link>
          </Flex>
        ),
        width: 150
      },
      {
        Header: '토론제목',
        accessor: 'title',
        maxWidth: 200,
        Cell: ({row}) => <Text maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{row.original.title}</Text>,
      },
      {
        Header: '입장 코드',
        accessor: 'code',
      },
      {
        Header: '참가인원',
        accessor: 'peopleCount'
      },
      // {
      //   Header: '참가자정보',
      //   accessor: 'members',
      //   Cell: ({row}) => <Button size="sm" onClick={() => openPeopleInfo(row.original)}>정보보기</Button>
      // },
      {
        Header: '링크',
        accessor: 'link',
        Cell: ({row}) => (
          <Box>
            <A href={`${row.original.link}/presentation`} isExternal><Button size="sm" variantColor="blue">화면보기</Button></A>
            <CopyToClipboard
              text={`${window.location.host}${row.original.link}`}
              onCopy={
                () => toast({
                  title: "링크 복사 완료",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                })
              }>
              <Button ml="8px" size="sm">링크복사</Button>
            </CopyToClipboard>
          </Box>)
      },
      {
        Header: '생성일',
        accessor: 'createdAtString'
      },
    ]
  }, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useFlexLayout);
  return (loading && docs) ? <Loading fullScreen /> : (
    <Box>
      <Box d="flex" justifyContent="flex-end" my="10px">
        <Button variantColor="teal" size="sm" onClick={onOpenAddition}>새로 만들기</Button>
      </Box>
      <Box {...getTableProps()}>
        <Box>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <Box borderBottomWidth="1px" borderBottomColor="gray.300" py="8px" {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <Box d="flex" justifyContent="center" alignItems="center" {...column.getHeaderProps()}>
                  {// Render the header
                  column.render('Header')
                  }
                </Box>
              ))
              }
            </Box>
          ))
          }
        </Box>
        {// Apply the table body props
        <Box {...getTableBodyProps()}>
          {// Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <Box {...row.getRowProps()}>
                {// Loop over the rows cells
                row.cells.map(cell => {
                  // Apply the cell props
                  return (
                    <Box py="6px" d="flex" justifyContent="center" alignItems="center" {...cell.getCellProps()}>
                      {// Render the cell contents
                      cell.render('Cell')
                      }
                    </Box>
                  )
                })
                }
              </Box>
            )
          })
          }
        </Box>
        }
      </Box>
      <AddDiscussionModal isOpen={isAdditionOpen} onClose={onCloseAddition} />
      {row && <AttendeeListModal responsors={row.members} isOpen={isPeopleInfoOpen} onClose={closePeopleInfo} />}
    </Box>
  )
}