import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';

const firebaseConfig = {
  apiKey: "AIzaSyBsaazsLYVqKpBZMA2hIgC07yHAR69BEk0",
  authDomain: "prj-bluecoop.firebaseapp.com",
  databaseURL: "https://prj-bluecoop.firebaseio.com",
  projectId: "prj-bluecoop",
  storageBucket: "prj-bluecoop.appspot.com",
  messagingSenderId: "202376632633",
  appId: "1:202376632633:web:030291f9787d6c7caef0e8",
  measurementId: "G-RQ53C64MNP"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <CSSReset />
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
