import {useEffect, useState} from 'react';
import firebase from 'firebase/app';

export const useUser = () => {
  const [user, setUser] = useState(null);
  const {currentUser} = firebase.auth();
  useEffect(() => {
    let subscribe = null;
    if ((!currentUser) && subscribe) {
      return () => subscribe();
    } else if (currentUser) {
      subscribe = firebase.firestore()
        .doc(`Users/${currentUser.uid}`)
        .onSnapshot((doc) => {
          if (doc) {
            setUser({
              id: doc.id,
              ...doc.data(),
            });
          }
        });
      return () => subscribe();
    }
  }, [currentUser]);
  return user;
};