import React, {useEffect, useState} from 'react';
import {
  Box,
  Text,
  Heading,
  Image
} from '@chakra-ui/core';
import {useParams} from 'react-router-dom';
import firebase from 'firebase/app';
import Poll from '../Poll';
import PollChart from '../../Component/PollChart';
import Loading from '../../Component/Loading';

export default function PollPresentation(props) {
  // const {docId, data, preview} = props;
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props.preview && props.docId && props.data) {
      setData(props.data);
      setLoading(false);
    } else {
      const subscribe = firebase.firestore().collection('Polls').doc(params.id)
        .onSnapshot(snapshot => {
          if (snapshot && snapshot.exists) {
            setData({id: snapshot.id, ...snapshot.data()});
            setLoading(false);
          }
        });
      return subscribe;
    }
  }, [props.preview, props.docId, props.data, params]);
  return loading ? <Loading fullScreen /> : (
    <Box width="100%" maxW="600px" mx="auto" pb="50px" pt="5%" px="5%">
      {data.presentation === 'VOTE' && (
        <Box>
          <Poll docId={params.id || props.docId} />
        </Box>
      )}
      {data.presentation === 'RESULT' && (
        <Box>
          <Text my="10px" textAlign="center" fontSize="16px" fontWeight="bold" color="gray.600">
            {data.title}
          </Text>
          {!!data.currentQuestionId && <Heading textAlign="center" my="10px" fontSize="20px">{data.questions[data.currentQuestionId].title}</Heading>}
          {(data.hostImage && data.hostImage.URL) && (
          <Box d="flex" justifyContent="center" alignItems="center" my="10px">
            <Image src={data.hostImage.URL} height="50px" marginLeft="6px" />
          </Box>
        )}
          <Box>
            {data.currentQuestionId && <PollChart data={data} />}
          </Box>
        </Box>
      )}
    </Box>
  )
}