import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Image, Text, Box, Input, Button, FormControl, FormErrorMessage } from '@chakra-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

export default function AdminSignIn(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('이메일을 입력해 주세요.').email('이메일 형식을 확인해 주세요.'),
    password: Yup.string().required('비밀번호를 입력해 주세요.'),
  })
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const subscribe = firebase.firestore().collection('Users').doc(user.uid).get().then(doc => {
          if (doc && doc.exists) {
            const data = doc.data();
            if (data.isAdmin) {
              history.push('/admin');
            }
          }
        });
        return subscribe;
      }
    });
  }, [history]);
  const onSubmit = (values) => {
    setLoading(true);
    const { email, password } = values;
    firebase.auth().signInWithEmailAndPassword(email, password)
      .catch(error => {
        console.error(error);
        if (error.code === 'auth/wrong-password') {
          alert('계정 정보를 다시 확인해 주세요.');
        } else {
          alert('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');
        }
        setLoading(false);
      })
      .then(() => {
        console.log('signed in')
        setLoading(false);
        if (history) {
          console.log('move to /admin');
          history.push('/admin');
        }
      })
  };
  return (
    <Box mt="50px">
      <Image src="/images/logo.png" alt="logo" maxHeight="150px" />
      <Text textAlign="center" mt="30px" mb="10px" fontWeight="bold">관리자 로그인</Text>
      <Box>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, }) => (
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={errors.email && touched.email}>
                <Input
                  name="email"
                  placeholder="이메일"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  my="5px"
                />
                {errors.email && touched.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={errors.password && touched.password}>
                <Input
                  name="password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="비밀번호"
                  my="5px"
                />
                {errors.password && touched.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
              </FormControl>
              <Button
                variantColor="blue"
                type="submit"
                w="100%"
                mt="10px"
                disabled={!values.email || !values.password}
                isLoading={loading}
              >
                로그인
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}