import React, {useState, useEffect, useMemo} from 'react';
import {Box, Text, Image} from '@chakra-ui/core';
import {useParams} from 'react-router-dom';
import firebase from 'firebase/app';
import Vote from './Vote';
import Loading from '../../Component/Loading';

export default function Poll(props) {
  const {docId} = props;
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const docRef = useMemo(() => firebase.firestore().collection('Polls').doc(id || docId), [id, docId]);
  useEffect(() => {
    if (id || docId) {
      const subscribe = docRef.onSnapshot(snapshot => {
        if (snapshot && snapshot.exists) {
          const doc = snapshot.data();
          setData({id: doc.id, ...doc})
          setLoading(false);
        }
      })
      return subscribe;
    }
  }, [docRef, id, docId]);
  return (loading || !data) ? <Loading fullScreen /> :(
    <Box h="100%" maxW="600px" mx="auto">
      {(data.hostImage && data.hostImage.URL) && (
      <Box d="flex" justifyContent="center" alignItems="center" my="10px">
        <Image src={data.hostImage.URL} height="50px" marginLeft="6px" />
      </Box>
      )}
      {!!data.currentQuestionId && (
        <Box>
          <Text textAlign="center" my="10px" fontWeight="bold" fontSize="18px">{data.questions[data.currentQuestionId].title}</Text>
        </Box>
      )}
      {(data.status !== 'ON') && (
        <Box>
          <Text textAlign="center" color="gray.400" fontSize="25px">다음 투표를 준비 중 입니다.</Text>
          <Text textAlign="center" color="gray.400" fontSize="20px">잠시만 기다려 주세요.</Text>
        </Box>
      )}
      {data.status === 'ON' && !data.currentQuestionId && (
        <Box>
        <Text textAlign="center" my="10px" fontWeight="bold" fontSize="18px">진행 중인 질문이 없습니다.</Text>
      </Box>
      )}
      {data.status === 'ON' && data.currentQuestionId && (
        <Vote data={data} docId={id || docId} />
      )}
    </Box>
  )
}