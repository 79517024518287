import React, {useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Flex,
  Box,
  Text,
} from "@chakra-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Formik} from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/app';
import {getRandomCode} from '../../Utils/utils';

const CustomDateInput = ({value, onClick}) => (
  <Button onClick={onClick} variantColor="teal" mr="20px" size="sm" variant="outline">
    {value}
  </Button>
)

export default function AddOnlinePolllModal(props) {
  const { isOpen, onClose } = props;
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('토론 주제를 입력해 주세요.'),
    code: Yup.string().required('입장 코드를 입력해 주세요.').length(6, '6자리 입장 코드를 입력해 주세요.'),
  });
  const setRandomCode = (setFieldValue) => {
    const code = getRandomCode(6);
    setFieldValue('code', code);
  }
  const onSubmit = (values) => {
    setLoading(true);
    const id = values.code;
    firebase.firestore().collection('OnlinePolls').doc(id)
      .set({
        id,
        title: values.title,
        description: '',
        createdAt: new Date(),
        comments: [],
        options: [],
        responses: [],
        questions: {},
        status: 'PAUSE',
        startAt: values.startAt,
        endAt: values.endAt,
        availableOptionCount: 1,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>온라인 투표 만들기</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              code: '',
              title: '',
              startAt: new Date(),
              endAt: new Date(),
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue}) => (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormControl isInvalid={errors.code && touched.code} my="15px">
                    <FormLabel htmlFor="code" fontsize="13px" fontWeight="bold">입장코드</FormLabel>
                    <InputGroup>
                      <InputLeftAddon children="1" />
                      <Input
                        id="code"
                        name="code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                      />
                      <InputRightElement w="6em">
                        <Button w="6em" onClick={() => setRandomCode(setFieldValue)} variantColor="teal">자동생성</Button>
                      </InputRightElement>
                    </InputGroup>
                    {errors.code && touched.code && <FormErrorMessage>{errors.code}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={errors.title && touched.title} my="15px">
                    <FormLabel htmlFor="title" fontsize="13px" fontWeight="bold">토론주제</FormLabel>
                    <Input
                      id="title"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    {errors.title && touched.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
                  </FormControl>
                  <Text fontWeight="bold">투표 기간</Text>
                  <Flex>
                    <Box>
                      <Text fontSize="14px">시작 날짜</Text>
                      <DatePicker
                        dateFormat="yyyy.MM.dd"
                        selected={values.startAt}
                        onChange={date => setFieldValue('startAt', date)}
                        customInput={<CustomDateInput />}
                      />
                    </Box>
                    <Box>
                      <Text fontSize="14px">종료 날짜</Text>
                      <DatePicker 
                        dateFormat="yyyy.MM.dd"
                        selected={values.endAt}
                        onChange={date => setFieldValue('endAt', date)}
                        customInput={<CustomDateInput />}
                      />
                    </Box>
                  </Flex>
                </ModalBody>
                <ModalFooter>
                  <Button type="button" variantColor="blue" mr={3} onClick={onClose}>
                    취소
                  </Button>
                  <Button type="submit" variant="ghost" isLoading={loading}>확인</Button>
                </ModalFooter>
              </form>
              )}
          </Formik>
        </ModalContent>
      </Modal>
  )
}