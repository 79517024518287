import React, {forwardRef, useMemo, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Link as A,
  Select,
  Divider,
  RadioButtonGroup
} from '@chakra-ui/core';
import firebase from 'firebase/app';
import {useParams, Link} from 'react-router-dom';
import PollPresentation from '../Poll/PollPresentation';
import Loading from '../../Component/Loading';

const CustomRadio = forwardRef((props, ref) => {
  const { isChecked, isDisabled, value, ...rest } = props;
  return (
    <Button
      ref={ref}
      variantColor={isChecked ? "blue" : "gray"}
      role="radio"
      isDisabled={isDisabled}
      {...rest}
    />
  );
});


export default function PollPresentationControl(props) {
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const docRef = useMemo(() => firebase.firestore().collection('Polls').doc(id), [id]);
  useEffect(() => {
    const subscribe = docRef.onSnapshot(snapshot => {
      if (snapshot && snapshot.exists) {
        setData({ id: snapshot.id, ...snapshot.data()});
      }
      setLoading(false);
    });
    return subscribe;
  }, [docRef]);
  const onPresetationChange = (value) => {
    docRef.update({
      presentation: value,
    })
  };
  const onCurrentQuestionChange = (value) => {
    docRef.update({
      currentQuestionId: value,
    })
  };
  const onStatusChange = (value) => {
    const onConfirm = () => {
      docRef.update({
        status: value,
      })
    };
    if (value === 'ON' && !data.currentQuestionId) {
      return alert('투표 질문을 선택해 주세요.')
    }
    const getStatusString = () => {
      if (value === 'ON') {
        return '시작';
      }
      if (value === 'OFF') {
        return '종료';
      }
      if (value === 'PAUSE') {
        return '중단';
      }
    }
    if (window.confirm(`투표를 ${getStatusString()}하시겠습니까?`)) {
      return onConfirm();
    }
  };
  const sortedOptions = useMemo(() => {
    if (data && data.questions) {
      return Object.values(data.questions)
      .filter(question => !question.deleted)
      .sort((a, b) => {
        if (a.number > b.number) {
          return 1;
        }
        return -1;
      })
    }
    return []
  }, [data])
  return (
    <Box maxW="900px" mx="auto" py="20px">
      <Link to="/admin/5/"><Button mb="20px">뒤로가기</Button></Link>
      <Heading>투표 진행</Heading>
      <Divider />
      {(loading || !data) ? <Loading /> : (
        <Box>
          <Heading my="30px" fontSize="18px" textAlign="center">{data.title}</Heading>
          <Box my="20px" rounded="lg" borderWidth="1px" borderColor="gray.200" p="15px">
            <Text fontWeight="bold" color="red.500">미리보기</Text>
            <Box height="350px" overflow="auto">
              <PollPresentation preview={true} data={data} docId={id} status={data.presentation} />
            </Box>
            <Box textAlign="right" mt="30px">
              <A href={`/5/${data.id}/presentation`} isExternal><Button leftIcon="external-link">크게보기</Button></A>
            </Box>
          </Box>
          <Box my="20px" textAlign="center">
            <Text fontWeight="bold" mb="20px">화면 제어</Text>
            <RadioButtonGroup
              defaultValue={data.presentation}
              onChange={onPresetationChange}
              isInline
            >
              <CustomRadio value="VOTE">투표화면</CustomRadio>
              <CustomRadio value="RESULT">결과화면</CustomRadio>
            </RadioButtonGroup>
          </Box>
          <Divider my="40px" />
          {data.presentation === 'VOTE' && (
            <Box my="20px" textAlign="center">
              <Text fontWeight="bold" mb="20px">투표 제어</Text>
              <Select mx="auto" maxW="600px" placeholder="질문을 선택해 주세요" flex="1" value={data.currentQuestionId} onChange={e => onCurrentQuestionChange(e.target.value)}>
                {sortedOptions.map(question => (
                  <option key={question.id} value={question.id}>{question.title}</option>
                ))}
              </Select>
              <RadioButtonGroup
                my="20px"
                value={data.status}
                onChange={onStatusChange}
                isInline
              >
                <CustomRadio value="PAUSE">투표대기</CustomRadio>
                <CustomRadio value="ON">투표시작</CustomRadio>
                <CustomRadio value="OFF">투표종료</CustomRadio>
              </RadioButtonGroup>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}