import React from 'react';
import {Spinner, Box} from '@chakra-ui/core';

export default function Loading (props) {
  const {fullScreen} = props;
  const options = fullScreen ? {
    d: 'flex',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } : {};
  return (
    <Box {...options}>
      <Spinner />
    </Box>
  )
}