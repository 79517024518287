import React, {useEffect, useState, useMemo} from 'react';
import {
  Heading,
  Button,
  ButtonGroup,
  Box,
  Text,
  Flex,
  Image,
  IconButton,
  Editable,
  EditableInput,
  EditablePreview,
  PseudoBox,
  useDisclosure,
  Divider,
} from "@chakra-ui/core";
import firebase from 'firebase/app';
import {Link, useParams} from 'react-router-dom';
import Loading from '../../Component/Loading';
import FileUploadModal from '../../Component/FileUploadModal';

const EditableInputForm = (props) => {
  const {defaultValue, onSubmit} = props;
  /* Here's a custom control */
  const EditableControls = ({ isEditing, onSubmit, onCancel, onRequestEdit }) => {
    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton icon="check" onClick={onSubmit} size="sm" variantColor="teal" />
        <IconButton icon="close" onClick={onCancel} size="sm" />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton ml="10px" size="sm" icon="edit" onClick={onRequestEdit} variantColor="blue" />
      </Flex>
    );
  }
  return (
    <Editable
      defaultValue={defaultValue}
      fontSize="18px"
      lineHeight="130%"
      isPreviewFocusable={false}
      submitOnBlur={false}
      onSubmit={onSubmit}
      borderWidth="1px"
      borderColor="gray.200"
      rounded="lg"
      p="5px 10px"
      alignItems="center"
      flex="1"
      placeholder=""
    >
      {props => (
        <Flex justifyContent="space-between">
          <EditablePreview />
          <EditableInput onBlur={null} flex="1" placeholder="" />
          <EditableControls {...props} />
        </Flex>
      )}
    </Editable>
  );
}

export default function DiscussionDetail(props) {
  const {id: docId} = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const {isOpen: isHostImageUploadOpen, onOpen: onHostImageUploadOpen, onClose: onHostImageUploadClose} = useDisclosure();
  const docRef = useMemo(() => firebase.firestore().collection('Discussions').doc(docId), [docId]);
  useEffect(() => {
    if (docId) {
      const subscribe = docRef
        .onSnapshot(snapshot => {
          if (snapshot && snapshot.exists) {
            setData({ id: snapshot.id, ...snapshot.data()});
          }
          setLoading(false);
        })
      return subscribe;
    }
  }, [docId, docRef]);
  const onHostImageUploaded = async (result) => {
    const {
      metadata: {
        fullPath, ref,
      },
    } = result;
    const downloadURL = await ref.getDownloadURL();
    const newImage = {
      URL: downloadURL,
      path: fullPath,
    }
    docRef.update({
      hostImage: newImage,
    })
    .then(() => {
      onHostImageUploadClose();
    })
    .catch((error) => console.error(error));
  }
  const onDeleteHostImageClick = () => {
    const confirm = window.confirm('주최자 이미지를 삭제하시겠습니까?');
    if (confirm) {
      docRef.update({ hostImage: {} })
        .then(() => {})
        .catch((error) => {
          alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
          console.error(error);
        });
    }
  }
  const onSubmit = (value, fieldName) => {
    console.log('onSubmit', value)
    setLoading(true);
    firebase.firestore().collection('Discussions').doc(docId)
      .update({
        [fieldName]: value,
        updatedAt: new Date(),
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  return (loading || !data) ? <Loading fullScreen /> : (
    <Box maxW="900px" mx="auto" py="20px">
      <Link to="/admin/3/"><Button mb="20px">뒤로가기</Button></Link>
      <Heading>상세 정보 및 수정</Heading>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontsize="13px" fontWeight="bold">입장 코드</Text>
        <Text color="gray.400" fontWeight="bold">3{data.id}</Text>
      </Box>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontsize="13px" fontWeight="bold" mb="5px">토론 주제</Text>
        <EditableInputForm fieldName="title" defaultValue={data.title} onSubmit={(value) => onSubmit(value, 'title')} />
      </Box>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontsize="13px" fontWeight="bold" mb="5px">공지사항</Text>
        <EditableInputForm fieldName="notice" defaultValue={data.notice} onSubmit={(value) => onSubmit(value, 'notice')} />
      </Box>
      <Divider my="10px" />
      <Box my="30px">
        <Text fontWeight="bold" mb="5px">주최자 로고</Text>
        <Box my="10px">
          <PseudoBox onClick={onHostImageUploadOpen} d="flex" alignItems="center" justifyContent="center" position="relative" _hover={{ borderWidth: '2px', borderColor: 'pink.400', cursor: 'pointer'}} height="50px" width="95%" rounded="lg" backgroundColor="gray.50">
            {data.hostImage && data.hostImage.URL ? (
              <>
                <Image src={data.hostImage.URL} width="100%" height="100%" objectFit="contain" rounded="lg" />
                <IconButton icon="close" size="xs" position="absolute" top={-5} right={-25} variantColor="pink" onClick={onDeleteHostImageClick} />
              </>
            ) : (
              <Button size="sm" leftIcon="add">이미지 추가</Button>
            )}
            <FileUploadModal isOpen={isHostImageUploadOpen} onClose={onHostImageUploadClose} onFileUploaded={onHostImageUploaded} />
          </PseudoBox>
        </Box>
      </Box>
    </Box>
  )
}