import React, {useState, useEffect, useMemo} from 'react';
import {
  Box,
  Button,
  Link as A,
  Text,
  Flex,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import {useTable, useFlexLayout} from 'react-table';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import AddPollModal from './AddPollModal';
import AttendeeListModal from '../../Component/AttendeeListModal';
import Loading from '../../Component/Loading';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function AdminPoll(props) {
  const [docs, setDocs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [row, setRow] = useState(null);
  const toast = useToast();
  const { isOpen: isPeopleInfoOpen, onOpen: onOpenPeopleInfo, onClose: onClosePeopleInfo } = useDisclosure(); 
  const { isOpen: isAdditionOpen, onOpen: onOpenAddition, onClose: onCloseAddition} = useDisclosure();
  
  useEffect(() => {
    const subscribe = firebase.firestore()
      .collection('Polls')
      .orderBy('createdAt', 'desc')
      .onSnapshot(snapshots => {
        if (snapshots) {
          const dataToSet = [];
          snapshots.forEach(snapshot => {
            if (snapshot.exists) {
              dataToSet.push({ id: snapshot.id, ...snapshot.data()});
            }
          })
          setDocs(dataToSet);
        }
        setLoading(false);
      })
    return subscribe;
  }, []);
  const closePeopleInfo = () => {
    setRow(null);
    onClosePeopleInfo();
  }
  const data = useMemo(() => {
    if (docs) {
      return docs.map(doc => {
        const validResponses = doc.responses
        .filter(response => {
            const question = doc.questions[response.questionId];
            if (!question || question.deleted) return false;
            const option = question.options[response.optionId];
            if (!option) return false;
            return !option.deleted;
          });
        const responsors = Object.values(validResponses.reduce((result, current) => {
          if (!result[current.userId]) {
            result[current.userId] = current.user;
          }
          return result;
        }, {}));
        return {
          ...doc,
          id: doc.id,
          code: `5${doc.id}`,
          title: doc.title,
          createdAt: doc.createdAt.toDate(),
          createdAtString: dayjs(doc.createdAt.toDate()).format('YYYY.MM.DD'),
          responseCount: responsors.length,
          responsors,
          link: `/5/${doc.id}`,
        };
      })
    } else {
      return [];
    }
  }, [docs]);
  const deletePoll = (docId) => {
    if (window.confirm(`5${docId} 투표를 삭제하시겠습니까?`)) {
      firebase.firestore().collection('Polls').doc(docId).delete()
      .then(() => console.log('Poll deleted'))
      .catch(error => console.error('@delete poll', error));
    }
  }
  const columns = useMemo(() => {
    return [
      {
        Header: '액션',
        accessor: 'id',
        Cell: ({row}) => (
          <Flex>
            <Button mr="8px" size="sm" variantColor="red" onClick={() => deletePoll(row.original.id)}>삭제</Button>
            <Link to={`/admin/5/${row.original.id}`}><Button mr="8px" size="sm">수정</Button></Link>
            <Link to={`/admin/5/${row.original.id}/presentation`}><Button size="sm" variantColor="blue">투표진행</Button></Link>
          </Flex>
        ),
        width: 250,
      },
      {
        Header: '투표제목',
        accessor: 'title',
        maxWidth: 200,
        Cell: ({row}) => <Text maxW="200px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{row.original.title}</Text>,
      },
      {
        Header: '입장코드',
        accessor: 'code',
      },
      {
        Header: '참가인원',
        accessor: 'responseCount'
      },
      // {
      //   Header: '참가자정보',
      //   accessor: 'responsors',
      //   Cell: ({row}) => <Button size="sm" onClick={() => openPeopleInfo(row)}>정보보기</Button>
      // },
      {
        Header: '링크',
        accessor: 'link',
        Cell: ({row}) => (
          <Box>
            <A href={row.original.link} isExternal><Button size="sm" variantColor="blue">화면보기</Button></A>
            <CopyToClipboard
              text={`${window.location.host}${row.original.link}`}
              onCopy={
                () => toast({
                  title: "링크 복사 완료",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                })
              }>
              <Button ml="8px" size="sm">링크복사</Button>
            </CopyToClipboard>
          </Box>)
      },
      {
        Header: '생성일',
        accessor: 'createdAtString'
      },
    ];
  }, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useFlexLayout);
  return loading ? <Loading fullScreen /> : (
    <Box>
      <Box d="flex" justifyContent="flex-end" my="10px">
        <Button variantColor="teal" size="sm" onClick={onOpenAddition}>새로 만들기</Button>
        <AddPollModal isOpen={isAdditionOpen} onClose={onCloseAddition} />
      </Box>
      <Box {...getTableProps()} style={{ overflowY: 'auto', overflowX: 'scroll', maxWidth: '100%'}}>
        <Box>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <Box borderBottomWidth="1px" borderBottomColor="gray.300" py="8px" {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <Box d="flex" justifyContent="center" alignItems="center" {...column.getHeaderProps()}>
                  {// Render the header
                  column.render('Header')
                  }
                </Box>
              ))
              }
            </Box>
          ))
          }
        </Box>
        {// Apply the table body props
        <Box {...getTableBodyProps()}>
          {// Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <Box {...row.getRowProps()}>
                {// Loop over the rows cells
                row.cells.map(cell => {
                  // Apply the cell props
                  return (
                    <Box py="6px" d="flex" justifyContent="center" alignItems="center" {...cell.getCellProps()}>
                      {// Render the cell contents
                      cell.render('Cell')
                      }
                    </Box>
                  )
                })
                }
              </Box>
            )
          })
          }
        </Box>
        }
      </Box>
      {row && <AttendeeListModal responsors={row.original.responsors} isOpen={isPeopleInfoOpen} onClose={closePeopleInfo} />}
    </Box>
  )
}