import React, {useEffect, useState, useMemo} from 'react';
import {
  Heading,
  Button,
  ButtonGroup,
  Box,
  Switch,
  Input,
  Text,
  Flex,
  IconButton,
  Editable,
  EditableInput,
  EditablePreview,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  PseudoBox,
  Image
} from "@chakra-ui/core";
import firebase from 'firebase/app';
import {useParams, Link} from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {MdDragHandle} from 'react-icons/md';
import Loading from '../../Component/Loading';
import FileUploadModal from '../../Component/FileUploadModal';
import { getRandomCode } from '../../Utils/utils';
import "react-datepicker/dist/react-datepicker.css";

const EditableInputForm = (props) => {
  const [text, setText] = useState(props.value);
  useEffect(() => {
    setText(props.value);
  }, [props.value])
  /* Here's a custom control */
  const EditableControls = ({ isEditing, onCancel, onRequestEdit, onDelete }) => {
    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton icon="check" onClick={() => props.onSubmit(text, props.fieldName)} size="sm" variantColor="teal" />
        <IconButton icon="close" onClick={onCancel} size="sm" />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton ml="10px" size="sm" icon="edit" onClick={onRequestEdit} variantColor="blue" />
        {onDelete && <Button ml="5px" variantColor="red" size="sm" onClick={onDelete}>삭제</Button>}
      </Flex>
    );
  }
  return (
    <Editable
      fontSize="18px"
      lineHeight="130%"
      isPreviewFocusable={false}
      submitOnBlur={false}
      borderWidth="1px"
      borderColor="gray.200"
      rounded="lg"
      p="5px 10px"
      alignItems="center"
      flex="1"
      placeholder=""
      value={text}
      onChange={setText}
      {...props}
    >
      {innerProps => (
        <Flex justifyContent="space-between">
          <EditablePreview value={text} />
          <EditableInput value={text} onChange={e => setText(e.target.value)} onBlur={null} flex="1" placeholder="" />
          <EditableControls {...innerProps} value={text} onDelete={props.onDelete} />
        </Flex>
      )}
    </Editable>
  );
}

const AddQuestionModal = (props) => {
  const {isOpen, onClose, docId, nextIndex, onAddQuestion} = props;
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const onSubmit = () => {
    const questionId = getRandomCode(4);
    setLoading(true);
    firebase.firestore().collection('Polls').doc(docId)
      .set({
        questions: {
          [questionId]: {
            id: questionId,
            number: nextIndex,
            title,
            selectMaxCount: 1,
            selectMinCount: 1,
            options: {},
          }
        }
      }, { merge: true })
      .then(() => {
        setTitle('');
        setLoading(false);
        onClose();
        onAddQuestion(questionId);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>질문 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontWeight="bold" mb="5px">질문 제목</Text>
            <Input value={title} onChange={e => setTitle(e.target.value)} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variantColor="red" mr={3} onClick={onClose}>
            취소
          </Button>
          <Button isLoading={loading} variantColor="teal" disabled={!title} onClick={onSubmit}>추가</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const AddOptionModal = (props) => {
  const {isOpen, onClose, docId, questionId, nextIndex} = props;
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const onSubmit = () => {
    const optionId = getRandomCode(4);
    setLoading(true);
    firebase.firestore().collection('Polls').doc(docId)
      .set({
        questions: {
          [questionId]: {
            options: {
              [optionId]: {
                id: optionId,
                number: nextIndex,
                title,
              }
            }
          }
        }
      }, { merge: true })
      .then(() => {
        setTitle('');
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>답변 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontWeight="bold" mb="5px">답변 제목</Text>
            <Input value={title} onChange={e => setTitle(e.target.value)} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variantColor="red" mr={3} onClick={onClose}>
            취소
          </Button>
          <Button isLoading={loading} variantColor="teal" disabled={!title} onClick={onSubmit}>추가</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default function Poll(props) {
  const {id: docId} = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const {isOpen: isAddQuestionOpen, onOpen: onAddQuestionOpen, onClose: onAddQuestionClose} = useDisclosure();
  const {isOpen: isAddOptionOpen, onOpen: onAddOptionOpen, onClose: onAddOptionClose} = useDisclosure();
  const {isOpen: isHostImageUploadOpen, onOpen: onHostImageUploadOpen, onClose: onHostImageUploadClose} = useDisclosure();
  const docRef = useMemo(() => firebase.firestore().collection('Polls').doc(docId), [docId]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  useEffect(() => {
    if (docId) {
      const subscribe = docRef
        .onSnapshot(snapshot => {
          if (snapshot && snapshot.exists) {
            setData({ id: snapshot.id, ...snapshot.data()});
          }
          setLoading(false);
        })
      return subscribe;
    }
  }, [docId, docRef]);
  const onSubmit = (value, fieldName) => {
    setLoading(true);
    firebase.firestore().collection('Polls').doc(docId)
      .update({
        [fieldName]: value,
        updatedAt: new Date(),
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  const onPersonalInfoPolicyChange = () => {
    firebase.firestore().collection('Polls').doc(docId)
      .update({isAnonymous: !data.isAnonymous})
  };
  const onSelectMinCountChange = (value) => {
    if (value < 1) {
      return alert('0 보다 큰 수를 선택해 주세요.')
    }
    docRef.set({
      questions: {
        [selectedQuestion]: {
          selectMinCount: value,
        },
      },
    }, { merge: true})
      .then(() => {
        console.log('selectCount changed');
      })
      .catch((error) => {
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  };
  const onSelectMaxCountChange = (value) => {
    if (value < 1) {
      return alert('0 보다 큰 수를 선택해 주세요.')
    }
    if (value < data.selectMinCount) {
      return alert('최소 선택 개수 보다 큰 수를 선택해 주세요.')
    }
    docRef.set({
      questions: {
        [selectedQuestion]: {
          selectMaxCount: value,
        },
      },
    }, { merge: true})
    .then(() => {
      console.log('selectCount changed');
    })
    .catch((error) => {
      alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
      console.error(error);
    })
  };

  const onDragEnd = (result) => {
    const { destination: {index}, draggableId: optionId, } = result;
    const options = Object.values(data.questions[selectedQuestion].options).sort((a, b) => {
      if (a.number > b.number) {
        return 1;
      }
      return -1;
    });
    const sourceIndex = options.findIndex(option => option.id === optionId);
    const destinationIndex = options.findIndex(option => option.number === index);
    let nextIndex = sourceIndex;
    if (destinationIndex === 0) {
      nextIndex = options[destinationIndex].number * 0.5; 
    } else if (destinationIndex === options.length - 1) {
      nextIndex = options[destinationIndex].number + 1;
    } else {
      nextIndex = (options[destinationIndex -1].number + options[destinationIndex].number) * 0.5;
    }
    docRef.set({
      questions: {
        [selectedQuestion]: {
          options: {
            [optionId]: {
              number: nextIndex,
            }
          }
        }
      }
    }, {merge: true})
    .then(() => {
      console.log('reordered')
    })
    .catch((error) => {
      alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
      console.error(error);
    })
  };
  const onOptionDelete = (optionId) => {
    if (window.confirm('답변을 삭제하시겠습니까?')) {
      docRef.set({
        questions: {
          [selectedQuestion]: {
            options: {
              [optionId]: {
                deleted: true,
              }
            },
          }
        }
      }, {merge: true}).then(() => console.log('option deleted')).catch(error => console.error(error))
    }
  };
  const onQuestionDelete = () => {
    if (window.confirm('질문을 삭제하시겠습니까?')) {
      docRef.set({
        currentQuestionId: data.currentQuestionId && data.currentQuestionId === selectedQuestion ? '' : (data.currentQuestionId || ''),
        questions: {
          [selectedQuestion]: {
            deleted: true,
          }
        }
      }, {merge: true}).then(() => {
        console.log('question deleted');
        setSelectedQuestion(null);
      }).catch(error => console.error(error))
    }
  };
  const onHostImageUploaded = async (result) => {
    const {
      metadata: {
        fullPath, ref,
      },
    } = result;
    const downloadURL = await ref.getDownloadURL();
    const newImage = {
      URL: downloadURL,
      path: fullPath,
    }
    docRef.update({
      hostImage: newImage,
    })
    .then(() => {
      onHostImageUploadClose();
    })
    .catch((error) => console.error(error));
  }
  const onDeleteHostImageClick = () => {
    const confirm = window.confirm('주최자 이미지를 삭제하시겠습니까?');
    if (confirm) {
      docRef.update({ hostImage: {} })
        .then(() => {})
        .catch((error) => {
          alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
          console.error(error);
        });
    }
  }
  return (loading || !data) ? <Loading fullScreen /> : (
    <Box maxW="900px" mx="auto" py="20px">
      <Link to="/admin/5"><Button mb="20px">뒤로가기</Button></Link>
      <Heading>상세 정보 및 수정</Heading>
      <Box my="30px">
        <Text fontWeight="bold">입장 코드</Text>
        <Text color="gray.400" fontWeight="bold">5{data.id}</Text>
      </Box>
      <Box my="30px">
        <Text fontWeight="bold">개인 정보 수집</Text>
        <Switch size="lg" mt="10px" isChecked={!data.isAnonymous} onChange={onPersonalInfoPolicyChange} />
      </Box>
      <Box my="30px">
        <Text fontWeight="bold" mb="5px">투표 제목</Text>
        <EditableInputForm fieldName="title" value={data.title} onSubmit={onSubmit} />
      </Box>
      <Box my="30px">
        <Text fontWeight="bold" mb="5px">주최자 로고</Text>
        <Box my="10px">
          <PseudoBox onClick={onHostImageUploadOpen} d="flex" alignItems="center" justifyContent="center" position="relative" _hover={{ borderWidth: '2px', borderColor: 'pink.400', cursor: 'pointer'}} height="50px" width="95%" rounded="lg" backgroundColor="gray.50">
            {data.hostImage && data.hostImage.URL ? (
              <>
                <Image src={data.hostImage.URL} width="100%" height="100%" objectFit="contain" rounded="lg" />
                <IconButton icon="close" size="xs" position="absolute" top={-5} right={-25} variantColor="pink" onClick={onDeleteHostImageClick} />
              </>
            ) : (
              <Button size="sm" leftIcon="add">이미지 추가</Button>
            )}
            <FileUploadModal isOpen={isHostImageUploadOpen} onClose={onHostImageUploadClose} onFileUploaded={onHostImageUploaded} />
          </PseudoBox>
        </Box>
      </Box>
      <Box my="30px">
        <Text fontWeight="bold" mb="5px">질문</Text>
        <Flex>
          <Select flex="1" placeholder="질문을 선택해 주세요." value={selectedQuestion || ''} onChange={e => setSelectedQuestion(e.target.value)}>
            {Object.values(data.questions)
            .filter(question => !question.deleted)
            .sort((a, b) => {
              if (a.number > b.number) {
                return 1;
              }
              return -1;
            }).map(question => (
              <option key={question.id} value={question.id}>{question.title}</option>
            ))}
          </Select>
          <Button variantColor="blue" leftIcon="add" ml="5px" onClick={onAddQuestionOpen}>질문 추가</Button>
          <AddQuestionModal docId={docId} isOpen={isAddQuestionOpen} onClose={onAddQuestionClose} onAddQuestion={setSelectedQuestion} nextIndex={Object.keys(data.questions).length} />
        </Flex>
        {selectedQuestion && (
          <Box my="10px" p="15px" backgroundColor="gray.50" rounded="lg">
            <Box>
              <Text fontWeight="bold">질문 내용</Text>
              <EditableInputForm
                fieldName={`questions.${selectedQuestion}.title`} 
                value={data.questions[selectedQuestion].title} 
                onSubmit={onSubmit} 
              /> 
            </Box>
            <Box my="20px">
              <Text fontWeight="bold">답변 선택 개수</Text>
              <Flex>
                <Box>
                  <Text fontSize="14px">최소 개수</Text>
                  <NumberInput size="sm" value={data.questions[selectedQuestion].selectMinCount} onChange={onSelectMinCountChange}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>
                <Box ml="10px">
                  <Text fontSize="14px">최대 개수</Text>
                  <NumberInput size="sm" value={data.questions[selectedQuestion].selectMaxCount} onChange={onSelectMaxCountChange}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>
              </Flex>
            </Box>
            <Box my="20px">
              <Text fontWeight="bold">답변</Text>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`${selectedQuestion}.options`}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {data.questions[selectedQuestion].options && Object.keys(data.questions[selectedQuestion].options).length > 0 && 
                      Object.values(data.questions[selectedQuestion].options)
                      .filter(option => !option.deleted)
                      .sort((a, b) => {
                        if (a.number > b.number) {
                          return 1;
                        }
                        return -1;
                      }).map(option => (
                        <Draggable key={option.id} draggableId={option.id} index={option.number}>
                          {(provided) => (
                            <Flex
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              rounded="lg"
                              my="5px"
                            >
                              <Flex justifyContent="center" alignItems="center" px="5px" backgroundColor="gray.400" borderBottomLeftRadius="lg" borderTopLeftRadius="lg">
                                <MdDragHandle color="#FFF" />
                              </Flex>
                              <Flex flex="1" backgroundColor="#FFF">
                                <EditableInputForm
                                  rounded={null}
                                  borderBottomRightRadius="lg"
                                  borderTopRightRadius="lg"
                                  fieldName={`questions.${selectedQuestion}.options.${option.id}.title`} 
                                  value={data.questions[selectedQuestion].options[option.id].title} 
                                  onSubmit={onSubmit} 
                                  onDelete={() => onOptionDelete(option.id)}
                                />
                              </Flex>
                            </Flex>
                          )}
                        </Draggable>
                      ))}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
              <Button leftIcon="add" variantColor="blue" onClick={onAddOptionOpen} my="20px" size="sm">답변 추가</Button>
              <AddOptionModal docId={docId} isOpen={isAddOptionOpen} onClose={onAddOptionClose} questionId={selectedQuestion} nextIndex={data.questions[selectedQuestion].options ? Object.keys(data.questions[selectedQuestion].options).length : 1} />
            </Box>
            <Button variantColor="red" mb="10px" onClick={onQuestionDelete}>삭제</Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}