import React, {useMemo, useState, useCallback, useEffect, useRef} from 'react';
import firebase from 'firebase/app';
import {
  Box,
  Text,
  Button,
  Flex,
} from '@chakra-ui/core';
import {FiAlertCircle} from 'react-icons/fi';
import { useUser } from '../../Hooks/useUser';

export default function Vote(props) {
  const {data, docId} = props;
  const [selected, setSelected] = useState({});
  const {currentUser} = firebase.auth();
  const user = useUser();
  const currentQuestionId = useRef();
  useEffect(() => {
    if (currentQuestionId && currentQuestionId.current && currentQuestionId.current !== data.currentQuestionId) {
      setSelected({});
    }
    if (data.currentQuestionId) {
      currentQuestionId.current = data.currentQuestionId;
    }
  }, [data]);
  const docRef = useMemo(() => firebase.firestore().collection('Polls').doc(docId), [docId]);

  const selectedCount = useMemo(() => {
    return Object.values(selected).filter(item => item === true).length;
  }, [selected]);
  const currentQuestion = useMemo(() => data.questions[data.currentQuestionId], [data]);
  const onSelect = (optionId) => {
    if (!selected[optionId] && selectedCount >= currentQuestion.selectMaxCount) {
      return alert(`최대 ${currentQuestion.selectMaxCount}까지 선택 가능합니다.`);
    }
    const newSelected = {...selected};
    newSelected[optionId] = !selected[optionId];
    setSelected(newSelected);
  }

  const votedBefore = useMemo(() => {
    if (data && data.responses) {
      return Object.values(data.responses).filter(response => response.questionId === data.currentQuestionId && response.userId === currentUser.uid).length > 0;
    }
    return false;
  }, [data, currentUser]);

  const onSubmit = useCallback(() => {
    if (!user) {
      return;
    }
    if (votedBefore) {
      return;
    }
    if (selectedCount < currentQuestion.selectMinCount) {
      return alert(`${currentQuestion.selectMinCount - selectedCount}개 더 선택해 주세요.`);
    }
    const responses = data.responses ? [...data.responses] : [];
    const checkedList = Object.keys(selected).filter(optionId => selected[optionId] === true);
    const valueToUpdate = checkedList.map(optionId => ({
      questionId: data.currentQuestionId,
      createdAt: new Date(),
      optionId,
      userId: currentUser.uid,
      user,
    }));
    docRef.update({
      responses: responses.concat(valueToUpdate),
    })
    .then(() => alert('투표 완료 되었습니다.'))
    .catch(error => {
      alert('죄송합니다. 잠시 후 다시 시도해 주세요.');
      console.error(error);
    })
  }, [user, votedBefore, data, currentUser, docRef, selected, selectedCount, currentQuestion]);
  const getOptionNumber = (index) => {
    const number = index + 1;
    return number < 10 ? `0${number}` : number;
  };
  const sortedOptions = useMemo(() => Object.values(currentQuestion.options)
    .filter(option => !option.deleted)
    .sort((a, b) => {
      if (a.number > b.number) return 1;
      return -1;
  }), [currentQuestion.options]);
  return (
    <Box w="100%">
      {((data.status === 'ON' || data.status === 'OFF') && data.currentQuestionId) && (
        <Box overflow="auto">
          {currentQuestion.selectMaxCount > 1 && (
            <Flex alignItems="center" py="2px">
              <FiAlertCircle style={{height: '21px', width: '21px', padding: '3px 0 2px', color: "#3182ce", marginRight: '3px'}} />
              <Text fontSize="14px" color="gray.600">중복 선택 <Text as="span" color="blue.600">{currentQuestion.selectMaxCount}번</Text> 가능</Text>
            </Flex>
          )}
          <Box w="100%">
            {sortedOptions
              .map((option, index) => (
                <Flex
                  width='100%'
                  borderWidth="1px"
                  backgroundColor="#FFF"
                  alignItems="center"
                  borderColor={selected[option.id] ? 'blue.600' : 'gray.300'}
                  disabled={votedBefore || data.status !== 'ON'}
                  key={option.id}
                  onClick={(votedBefore || data.status !== 'ON') ? undefined : () => onSelect(option.id)}
                  py="10px"
                  my="5px"
                  cursor="pointer"
                >
                  <Text fontWeight="600" w="3em" textAlign="center" color={selected[option.id] ? 'blue.600' : votedBefore ? 'gray.300' : 'inherit'}>{getOptionNumber(index)}</Text>
                  <Text fontWeight="600" flex="1" color={selected[option.id] ? 'blue.600' : votedBefore ? 'gray.300' : 'inherit'}>{option.title}</Text>
                </Flex>
            ))}
          </Box>
        </Box>
      )}
      <Box textAlign="center">
        <Button onClick={onSubmit} disabled={votedBefore || data.status !== 'ON' || !selectedCount} size="lg" width=" 280px" my="20px">
          {data.status === 'OFF'
            ? '투표 마감'
            : votedBefore
              ? '투표완료'
              : '투표하기'
          }
        </Button>
      </Box>
    </Box>
  )
}