import React, {useMemo} from 'react';
import {Box, Flex, Text, Divider} from '@chakra-ui/core';

export default function PollChart(props) {
  const {data: {responses, questions, currentQuestionId}} = props;
  const data = useMemo(() => {
    if (!responses || !responses.length) {
      return {};
    }
    const validResponses = responses
      .filter(response => {
        if (currentQuestionId !== response.questionId) return false;
        const question = questions[response.questionId];
        if (!question || question.deleted) return false;
        const option = question.options[response.optionId];
        if (!option) return false;
        return !option.deleted;
      });
    return validResponses.reduce((result, item) => {
          if (!result[item.optionId]) {
            result[item.optionId] = 0;
          }
          result[item.optionId] += 1;
        return result;
      }, {});
  }, [responses, questions, currentQuestionId]);
  const total = useMemo(() => {
    return Object.values(data).reduce((result, count) => {
      result += count;
      return result;
    }, 0);
  }, [data]);
  const currentQuestion = useMemo(() => questions[currentQuestionId], [questions, currentQuestionId])
  const currentQuestionOptions = useMemo(() => {
    return Object.keys(currentQuestion.options).filter(optionId => {
      return currentQuestion.options[optionId] && !currentQuestion.options[optionId].deleted
    }).sort((a, b) => {
      return currentQuestion.options[a].number > currentQuestion.options[b].number ? 1 : -1
    })
  }, [currentQuestion])
  return (
    <Box>
      <Text fontSize="14px" textAlign="center">투표 결과 - 총 <Text color="blue.500" as="span">{total}</Text>표</Text>
      <Divider mb="15px" />
      {currentQuestionOptions.map((optionId, index) => (
        <Flex key={optionId} my="10px">
          <Box color="gray.800" w="45px" d="flex" justifyContent="center" alignItems="center">
            {index + 1}번
          </Box>
          <Box flex="1">
            {data[optionId] ? (<Flex>  
              <Box backgroundColor="blue.500" flex={(data[optionId] / total) * 100 || 0}>
                <Text fontWeight="bold" m="5px" fontSize="13px" color="#FFF" textAlign="right">{data[optionId] || 0}표 ({((data[optionId] / total) * 100 || 0).toFixed(1)}%)</Text>
              </Box>
              <Box flex={(100 - (data[optionId] / total) * 100) || 0}/>
            </Flex>) : <Text fontWeight="bold" m="5px" fontSize="13px" color="blue.500">0 표 </Text>}
            <Text fontSize="13px" color="gray.800">{currentQuestion.options[optionId].title}</Text>
          </Box>
        </Flex>
      ))}
      <Divider mt="15px" />
      <Flex>
        <Box w="45px" />
        <Box flex="1">
          <Text color="gray.800" fontSize="14px" textAlign="right">25%</Text>
        </Box>
        <Box flex="1" backgroundColor="yellow">
          <Text color="gray.800" fontSize="14px" textAlign="right">50%</Text>
        </Box>
        <Box flex="1">
          <Text color="gray.800" fontSize="14px" textAlign="right">75%</Text>
        </Box>
        <Box flex="1">
          <Text color="gray.800" fontSize="14px" textAlign="right">100%</Text>
        </Box>
      </Flex>
    </Box>
  )
}