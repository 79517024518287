import React, {useEffect, useState, useMemo} from 'react';
import {
  Badge,
  Box,
  Button,
  Heading,
  Text,
  Textarea,
  Divider,
  Image,
  Input,
  Flex,
  useDisclosure,
} from '@chakra-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {useParams} from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
import Loading from '../../Component/Loading';
import {useUser} from '../../Hooks/useUser';
import Chart from './Chart';
import {MdCheckBox, MdCheckBoxOutlineBlank} from 'react-icons/md';
import {FiAlertCircle} from 'react-icons/fi';

const StatusBadge = ({status}) => {
  if (!status) return null;
  if (status === 'ON') {
    return <Badge variantColor="blue" ml={[0, 0, '0.3em']} mr={['0.3em', '0.3em', 0]}>진행</Badge>
  }
  if (status === 'OFF') {
    return <Badge variantColor="gray" ml={[0, 0, '0.3em']} mr={['0.3em', '0.3em', 0]}>종료</Badge>
  }
  if (status === 'PAUSE') {
    return <Badge variantColor="yellow" ml={[0, 0, '0.3em']} mr={['0.3em', '0.3em', 0]}>중지</Badge>
  }
}

const DateTimeString = ({timestamp}) => {
  if (!timestamp) return null;
  return <Text color="gray.500" fontSize="15px">{dayjs(timestamp.toDate()).format('YYYY.MM.DD')}</Text>;
}

const Dday = ({timestamp}) => {
  if (!timestamp) return null;
  const today = new Date().getDate();
  const targetDay = timestamp.toDate().getDate();
  if (today > targetDay) return null;
  return <Badge variantColor="red" ml={[0, 0, '0.3em']} mr={['0.3em', '0.3em', 0]}>D-{targetDay - today}</Badge>
}

const ResponseCount = ({responses}) => {
  if (!responses) return null;
  const count = responses.length;
  return <Text fontSize="14px" ml={[0, 0, '0.3em']} mr={['0.3em', '0.3em', 0]}>투표 수 : <Text as="span" color="blue.500">{count}</Text></Text>;
}
const CommentCount = ({comments}) => {
  if (!comments) return null;
  const count = comments.length;
  return <Text fontSize="14px" ml={[0, 0, '0.3em']} mr={['0.3em', '0.3em', 0]}>댓글 수 : <Text as="span" color="blue.500">{count}</Text></Text>;
}
const Questions = ({question, user, docId, responses, status}) => {
  const {options, selectMinCount, selectMaxCount, title} = question;
  const [check, setCheck] = useState({});
  const [loading, setLoading] = useState(false);
  const {isOpen, onClose, onOpen} = useDisclosure();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [optionToLookInto, setOptionToLookInto] = useState(null);
  const openOptionDetail = (index) => {
    setOptionToLookInto(options[index]);
    onOpen();
  };
  const onSelectOption = (optionId) => {
    if (status !== 'ON') return;
    if (check[optionId]) return setCheck({...check, [optionId]: false});
    const newCheckedList = {...check, [optionId]: true}
    const checkedList = Object.keys(newCheckedList).filter(optionId => {
      return newCheckedList[optionId] === true;
    });
    if (checkedList.length > selectMaxCount) {
        alert(`최대 ${selectMaxCount}개까지 선택할 수 있습니다.`);
    } else {
      return setCheck(newCheckedList);
    }
  }
  const onSubmit = () => {
    if (!user) return;
    if (status !== 'ON') return;
    const checkedList = Object.keys(check).filter(optionId => {
      return check[optionId] === true;
    });
    if (!checkedList.length) {
      return alert('답변을 선택해 주세요.');
    }
    if (checkedList.length < selectMinCount) {
      return alert(`최소 ${selectMinCount}개 이상 선택해 주세요.`);
    }
    if (checkedList.length > selectMaxCount) {
      return alert(`최대 ${selectMaxCount}개까지 선택할 수 있습니다.`)
    }
    const valueToUpdate = checkedList.map(optionId => ({
      questionId: question.id,
      optionId,
      userId: user.id,
      createdAt: new Date(),
      user,
    }));
    setLoading(true);
    firebase.firestore().collection('OnlinePolls').doc(docId)
      .set({
        responses: responses.concat(valueToUpdate)
      }, { merge: true })
      .then(() => {
        setLoading(false);
        alert('투표 완료 되었습니다.');
      })
      .catch(error => {
        setLoading(false);
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
        console.error(error);
      })
  }
  const previousVoting = responses.filter(response => response.questionId === question.id && response.userId === user.id);
  const chartData = useMemo(() => {
    const defaultData = options.map((option, index) => ({
      id: option.id,
      label: option.title,
      count: 0,
    }))
    return responses.filter(response => {
      if (question.deleted) return false;
      const optionIndex = question.options.findIndex(option => option.id === response.optionId);
      if (optionIndex < 0) return false;
      return !question.options[optionIndex].deleted;
    }).reduce((acc, response) => {
      const index = acc.findIndex(el => el.id === response.optionId);
      if (index >= 0) {
        acc[index].count += 1;
      }
      return acc;
    }, defaultData)
  }, [options, responses, question]);

  return options && (
    <Box my="50px">
      <Text fontWeight="bold" fontSize="20px" my="20px">{title}</Text>
      <Flex alignItems="center">
        <FiAlertCircle style={{height: '18px', width: '18px', padding: '2px 0 0px', color: "#3182ce", marginRight: '3px'}} />
        <Flex fontSize="14px">
          {selectMinCount > 1 && <Text mr="0.4em">최소 <Text as="span" color="blue.400">{selectMinCount}개</Text></Text>}
          <Text>최대 <Text as="span" color="blue.400">{selectMaxCount}개</Text> 선택 가능</Text>
        </Flex>
      </Flex>
      {options.filter(option => !option.deleted).map((option, index) => (
        <Box key={option.id} d="flex" justifyContent="space-between" alignItems="center" my="10px" py="10px" px="15px" backgroundColor="gray.50" rounded="lg">
          <Box d="flex" alignItems="center" flex="1" flexWrap="wrap">
            <Text fontWeight="bold" mr="0.3em" lineHeight="140%" cursor="pointer">
              {`${index + 1}. ${options[index].title}`}
            </Text>
            {options[index].images && options[index].images.length > 0 && (
              <Button size="xs" variantColor="blue" m="5px" onClick={() => openOptionDetail(index)}>상세보기</Button>
            )}
          </Box>
          {check[option.id]
            ? <MdCheckBox style={{width: 20, height: 20, color: '#3182ce'}} onClick={() => onSelectOption(option.id)} />
            : <MdCheckBoxOutlineBlank style={{width: 20, height: 20, color: '#3182ce'}} onClick={() => onSelectOption(option.id)} />
          }
        </Box>
      ))}
      {status === 'ON' && (
        <Box my="20px" d="flex" justifyContent="center" alignItems="center" flexDirection="column" width="80%" maxW="290px" mx="auto">
          <Button
            w="100%"
            size="lg"
            variantColor="blue"
            isLoading={loading}
            disabled={previousVoting && previousVoting.length > 0}
            onClick={onSubmit}
          >
            {previousVoting && previousVoting.length > 0 ? '투표완료' : '투표하기'}
          </Button>
          {previousVoting && previousVoting.length > 0  && <Text color="gray.400" my="5px" fontSize="13px">{dayjs(previousVoting[0].createdAt.toDate()).format('YYYY.MM.DD LT')}에 투표하셨습니다.</Text>}
        </Box>
      )}
      {status === 'OFF' && (
        <>
          <Divider my="20px"/>
          <Box d="flex" width="100%" flexDirection="column" alignItems="center">
            <Chart data={chartData} />
          </Box>
        </>
      )}
      {(optionToLookInto && isOpen) && (
        <Lightbox
          mainSrc={optionToLookInto.images[photoIndex].URL}
          nextSrc={optionToLookInto.images[(photoIndex + 1) % optionToLookInto.images.length]}
          prevSrc={optionToLookInto.images[(photoIndex + optionToLookInto.images.length - 1) % optionToLookInto.images.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={() => setPhotoIndex(prev => (prev + optionToLookInto.images.length - 1) % optionToLookInto.images.length)}
          onMoveNextRequest={() => setPhotoIndex(prev => (prev + 1) % optionToLookInto.images.length)}
          imageLoadErrorMessage=""
        />
      )}
    </Box>
  )
}
const Comments = ({comments, user, docId}) => {
  const [nickName, setNickName] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  if (!comments || !user) return null;
  const onSubmit = () => {
    if (!docId) return;
    setLoading(true);
    firebase.firestore().collection('OnlinePolls').doc(docId)
      .update({
        comments: firebase.firestore.FieldValue.arrayUnion({
          createdAt: new Date(),
          nickName,
          userId: user.id,
          text,
        })
      })
      .then(() => {
        setLoading(false);
        setText('');
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        alert('오류가 발생했습니다. 관리자에게 문의해 주세요.');
      })
  }
  const onNickNameChange = (value) => {
    if (value && value.length > 10) {
      return alert('10자 이하로 작성해 주세요.');
    }
    return setNickName(value);
  }
  const onCommentChange = (value) => {
    if (value && value.length > 1000) {
      return alert('1000자 이하로 작성해 주세요.');
    }
    return setText(value);
  }
  return (
    <Box>
      <Box backgroundColor="gray.50" p="20px" rounded="lg" mb="20px">
        <Box>
          <Text fontSize="13px" mb="5px" fontWeight="600">댓글</Text>
          <Input placeholder="이름" value={nickName} onChange={e => onNickNameChange(e.target.value)} />
          <Textarea mt="8px" flex="1" resize="none" h="80px" minW="240px" value={text} onChange={e => onCommentChange(e.target.value)} placeholder="댓글 내용을 입력해 주세요." />
          <Text textAlign="right" fontSize="13px" color="gray.400">{text.length}/1000자</Text>
          <Box d="flex" justifyContent="flex-end">
            <Button mt="10px" h={['40px', '60px']} w={['100%','150px']} disabled={!nickName || !text} isLoading={loading} onClick={onSubmit}>
              등록
            </Button>
          </Box>
        </Box>
      </Box>
      {comments.map(comment => (
        <Box key={comment.createdAt.toDate()} py="10px">
          <Box d="flex" alignItems="center">
            <Text fontWeight="bold" mb="5px">{comment.nickName}</Text>
            <Text fontSize="12px" color="gray.400" ml="1em">{dayjs(comment.createdAt.toDate()).format('YYYY.MM.DD LT')}</Text>
          </Box>
          <Text whiteSpace="pre-line">{comment.text}</Text>
          <Divider />
        </Box>
      ))}
    </Box>
  )
}
export default function OnlinePoll(props) {
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const user = useUser();
  useEffect(() => {
    const subscribe = firebase.firestore().collection('OnlinePolls').doc(id).onSnapshot(snapshot => {
      if (snapshot && snapshot.exists) {
        const doc = snapshot.data();
        setData({ id: doc.id, ...doc});
      }
      setLoading(false);
    })
    return subscribe;
  }, [id]);
  useEffect(() => {
    if (data && data.endAt && data.status) {
      const {id, status, endAt} = data;
      const expired = dayjs(endAt.toDate());
      const now = dayjs(new Date());
      if (status === 'ON' && now.isAfter(expired)) {
        firebase.firestore().collection('OnlinePolls').doc(id)
          .update({status: 'OFF'})
      }
    }
  }, [data]);
  return loading ? <Loading fullScreen /> :(
    <Box w="100%" pb="50px" pt="5%" px="5%">
      <Box>
        <Text my="20px" mx="auto" backgroundColor="blue.500" color="#FFF" w="7em" py="0.3em" fontWeight="bold" textAlign="center">온라인투표</Text>
        <Heading my="5px" textAlign="center">{data.title}</Heading>
        {(data.hostImage && data.hostImage.URL) && (
          <Box d="flex" justifyContent="center" alignItems="center" my="10px">
            <Image src={data.hostImage.URL} height="50px" marginLeft="6px" />
          </Box>
        )}
      </Box>
      <Box d="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Box d="flex" alignItems="center" flexWrap="wrap">
          <Text color="gray.500" fontSize="15px" mr="0.3em">투표 기간</Text>
          <Box d="flex" alignItems="center" flexWrap="wrap">
            <DateTimeString timestamp={data.startAt} />
            <Text color="gray.500" fontSize="15px" mx="0.3em">~</Text>
            <DateTimeString timestamp={data.endAt} />
          </Box>
          <Box d="flex" alignItems="center" flexWrap="wrap" py="5px">
            <StatusBadge status={data.status} />
            <Dday timestamp={data.endAt} />
          </Box>
        </Box>
        <Box d="flex" justifyContent="flex-end" alignItems="center" py="5px">
          <ResponseCount responses={data.responses} />
          <CommentCount comments={data.comments} />
        </Box>
      </Box>
      <Divider mb="30px" />
      {data.image && <Image src={data.image.URL} rounded="lg" mx="auto" />}
      {data.description && (
        <Box>
          <Text fontWeight="bold" mt="20px" mb="10px">투표취지</Text>
          <Text whiteSpace="pre-line" wordBreak="keep-all">{data.description}</Text>
        </Box>
      )}
      {data.questions && Object.values(data.questions).filter(question => !!question).length > 0 && Object.values(data.questions).filter(question => !!question).map(question => (
        <Box key={question.id}>
          <Divider my="30px" />
          <Questions
            user={user}
            docId={id}
            question={question}
            responses={data.responses || []}
            status={data.status}
          />
        </Box>
      ))}
      <Divider my="30px" />
      <Comments comments={data.comments} user={user} docId={id} />
    </Box>
  )
}