import React, {useMemo} from 'react';
import {Box, Flex, Text, Divider} from '@chakra-ui/core';

export default function Chart(props) {
  const {data} = props;
  const total = useMemo(() => {
    return data.reduce((result, data) => {
      result += data.count || 0;
      return result;
    }, 0);
  }, [data]);
  return (
    <Box w="100%">
      <Text fontSize="14px" color="gray.500" textAlign="right">총 <Text color="blue.500" as="span">{total}</Text>표</Text>
      <Divider mb="15px" />
      {data.map((option, index) => (
        <Flex key={option.id} my="10px">
          <Box color="gray.500" w="2em" d="flex" justifyContent="center" alignItems="flex-start">
            {index + 1}번
          </Box>
          <Box flex="1">
            <Flex>  
              <Box backgroundColor="blue.500" flex={((option.count / total) * 100) || 0}>
                <Text fontWeight="bold" m="5px" fontSize="13px" color="#FFF" textAlign="right">{option.count}표 ({((option.count / total) * 100) || 0}%)</Text>
              </Box>
              <Box flex={(100 - (option.count / total) * 100) || 0}/>
            </Flex>
            <Text fontSize="13px" color="gray.500">{option.label}</Text>
          </Box>
        </Flex>
      ))}
      <Divider mt="15px" />
      <Flex>
        <Box w="45px" />
        <Box flex="1">
          <Text color="gray.500" fontSize="14px" textAlign="right">25%</Text>
        </Box>
        <Box flex="1" backgroundColor="yellow">
          <Text color="gray.500" fontSize="14px" textAlign="right">50%</Text>
        </Box>
        <Box flex="1">
          <Text color="gray.500" fontSize="14px" textAlign="right">75%</Text>
        </Box>
        <Box flex="1">
          <Text color="gray.500" fontSize="14px" textAlign="right">100%</Text>
        </Box>
      </Flex>
    </Box>
  )
}